import { FC, PropsWithChildren } from "react";
import { resolveStyle, StyleProps } from "../types";

export type BoxProps = PropsWithChildren<StyleProps>;

const Box: FC<BoxProps> = ({ children, ...props }) => {
  return <div className={resolveStyle(props)}>{children}</div>;
};

export default Box;
