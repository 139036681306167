import { FC, useContext } from "react";
import Text from "../../../Widgets/Text/Text";
import Button from "../../../Widgets/Button/Button";
import ImageComponent from "../../../Layout/ImageComponent";
import { LanguageContext } from "../../../Layout/Page";
import { classNames } from "../../../utils/string";

const translations = {
  en: {
    interested: "Interested in this property?",
    contactMike: "Contact Mike van Houtum for more information or to schedule a viewing.",
    sendWhatsapp: "Send Mike a Whatsapp message"
  },
  es: {
    interested: "¿Interesado en esta propiedad?",
    contactMike: "Contacte a Mike van Houtum para obtener más información o para programar una visita.",
    sendWhatsapp: "Envíe un mensaje de Whatsapp a Mike"
  },
  nl: {
    interested: "Geïnteresseerd in deze woning?",
    contactMike: "Neem contact op met Mike van Houtum voor meer informatie of om een bezichtiging in te plannen.",
    sendWhatsapp: "Stuur Mike een Whatsapp-bericht"
  },
  de: {
    interested: "Interessiert an dieser Immobilie?",
    contactMike: "Kontaktieren Sie Mike van Houtum für weitere Informationen oder um eine Besichtigung zu vereinbaren.",
    sendWhatsapp: "Senden Sie Mike eine Whatsapp-Nachricht"
  }
};

export type ContactMikeProps = {
  whatsappLink: string;
  small?: boolean;
};

const ContactMike: FC<ContactMikeProps> = ({ whatsappLink, small = false }) => {
  const { language } = useContext(LanguageContext);
  return (
    <div
      className={classNames(
        "w-full p-1 md:px-4 border flex flex-row flex-wrap items-center justify-center gap-4",
        !small && "md:py-8"
      )}
    >
      <div className="md:flex-1 max-w-full flex flex-col items-center">
        <Text type="h4" className={classNames(small ? "mt-0" : "mt-8")}>
          {translations[language].interested}
        </Text>
        <Text className={classNames("mt-4 w-[32rem] max-w-full", small && "hidden")}>
          {translations[language].contactMike}
        </Text>
        <div className="mx-auto w-96 max-w-full md:text-left">
          <div role="button" aria-label="Mike's phone number">
            <span className="bold w-12 inline-block">TEL:</span>&nbsp;
            <span
              className="text-primary underline"
              onClick={() => window.open("tel://+31623700433", "_blank", "noreferrer")}
            >
              +31 6 237 004 33
            </span>
          </div>
          <div role="button" aria-label="Mike's email address" className="mb-4">
            <span className="bold w-12 inline-block">EMAIL:</span>&nbsp;
            <span
              className="text-primary underline"
              onClick={() => window.open("mailto://info@fresh-propertymanagement.com", "_blank", "noreferrer")}
            >
              info@fresh-propertymanagement.com
            </span>
          </div>
        </div>
        <Button className="hidden md:flex" onClick={() => window.open(whatsappLink, "_blank", "noreferrer")}>
          {translations[language].sendWhatsapp}
        </Button>
      </div>
      <div className="flex flex-col items-center gap-4">
        <ImageComponent
          className="max-w-full w-[250px] aspect-square object-cover rounded-full"
          src={`/team/mike_van_houtum.webp`}
        />
        <Button
          className={classNames("md:hidden", !small && "mb-4")}
          onClick={() => window.open(whatsappLink, "_blank", "noreferrer")}
        >
          {translations[language].sendWhatsapp}
        </Button>
      </div>
    </div>
  );
};

export default ContactMike;
