import { FC, useRef } from "react";
import Page from "../../Layout/Page";
import HomeCallToAction from "../Home/HomeCallToAction";
import HomeHassleFree from "../Home/HomeHassleFree";

export type ServicesProps = {};

const Services: FC<ServicesProps> = ({}) => {
  const hassleFreeRef = useRef<HTMLDivElement>(null);
  return (
    <Page>
      <HomeCallToAction hassleFreeRef={hassleFreeRef} />
      <HomeHassleFree ref={hassleFreeRef} />
    </Page>
  );
};

export default Services;
