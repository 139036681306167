import { FC, useContext } from "react";
import Box from "../../Layout/Box";
import ImageComponent from "../../Layout/ImageComponent";
import Text from "../../Widgets/Text/Text";
import { Icon } from "@iconify/react";
import star from "@iconify-icons/ph/star-fill";
import { LanguageContext } from "../../Layout/Page";

export type HolidayReviewProps = {};

const translations = {
  en: [
    `We couldn't be happier with the results! We are entrusting them two more properties to manage.`,
    `Darren G. | property owner in Alcaucín`
  ],
  es: [
    `¡No podríamos estar más felices con los resultados! Les estamos confiando dos propiedades más para administrar.`,
    `Darren G. | propietario de la propiedad en Alcaucín`
  ],
  nl: [
    `We konden niet blijer zijn met de resultaten! We vertrouwen het beheer van nog twee eigendommen aan hen toe.`,
    `Darren G. | eigenaar van vakantiewoning in Alcaucín`
  ],
  de: [
    `Wir könnten mit den Ergebnissen nicht zufriedener sein! Wir betrauen sie mit der Verwaltung von zwei weiteren Objekten.`,
    `Darren G. | Immobilienbesitzer in Alcaucín`
  ]
};
const HolidayReview: FC<HolidayReviewProps> = ({}) => {
  const { language } = useContext(LanguageContext);
  let next = 0;
  const text = () => translations[language][next++] ?? "";
  return (
    <Box backgroundColor="grey">
      <div className="grid grid-cols-1 lg:grid-cols-2">
        <ImageComponent className="h-full" src="/alcaucin.webp" />
        <div className="px-8 py-12 text-center flex flex-col items-center justify-center">
          <Text className="max-w-xl" type="h2">
            <em>“{text()}”</em>
          </Text>
          <div className="flex flex-col lg:flex-row items-center">
            <Text type="h6">{text()}</Text>
            <div className="flex flex-row items-center ml-2 lg:ml-0">
              <Icon icon={star} className="ml-1 text-yellow-400" />
              <Icon icon={star} className="ml-1 text-yellow-400" />
              <Icon icon={star} className="ml-1 text-yellow-400" />
              <Icon icon={star} className="ml-1 text-yellow-400" />
              <Icon icon={star} className="ml-1 text-yellow-400" />
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default HolidayReview;
