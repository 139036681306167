import { FC, useContext } from "react";
import Box from "../../Layout/Box";
import Container from "../../Layout/Container";
import Text from "../../Widgets/Text/Text";
import LinkButton from "../../Widgets/Button/LinkButton";
import HorizontalRuler from "../../Widgets/Separators/HorizontalRuler";
import { LanguageContext } from "../../Layout/Page";

export type HomeMeetTheFoundersProps = {};

const translations = {
  en: [
    `Co-founder`,
    `Meet the founders`,
    `We as founders all own rental properties ourselves. The lessons learned and passion for the business led to the decision to make our experience accessible through FRESH.`,
    `Of course we have a team of dedicated professionals to support you:`,
    `Meet our entire team`
  ],
  es: [
    `Cofundador`,
    `Conozca a los fundadores`,
    `Nosotros mismos, como fundadores, somos propietarios de viviendas en alquiler. Las lecciones aprendidas y la pasión por el negocio nos llevaron a tomar la decisión de hacer accesible nuestra experiencia a través de FRESH.`,
    `Por supuesto, contamos con un equipo de profesionales dedicados a apoyarte:`,
    `Conoce a todo nuestro equipo`
  ],
  nl: [
    `Medeoprichter`,
    `Ontmoet de oprichters`,
    `We hebben als oprichters allemaal zelf huurwoningen. De geleerde lessen en passie voor het vak leidden tot de beslissing om onze ervaring toegankelijk te maken via FRESH.`,
    `Natuurlijk hebben we een team van toegewijde professionals om u te ondersteunen:`,
    `Leer ons hele team kennen`
  ],
  de: [
    `Mitgründer`,
    `Treffen Sie die Gründer`,
    `Wir als Gründer besitzen alle selbst Mietobjekte. Die daraus gewonnenen Erkenntnisse und die Leidenschaft für das Geschäft führten zu der Entscheidung, unsere Erfahrung durch FRESH zugänglich zu machen.`,
    `Natürlich haben wir ein Team von engagierten Fachleuten, die Sie unterstützen:`,
    `Treffen Sie unser gesamtes Team`
  ]
};

const HomeMeetTheFounders: FC<HomeMeetTheFoundersProps> = ({}) => {
  const { language } = useContext(LanguageContext);
  let next = 0;
  const text = () => translations[language][next++] ?? "";
  const cofounder = text();
  return (
    <Box backgroundColor="white">
      <Container>
        <Text className="mt-8" type="h2">
          {text()}
        </Text>
        <div className="flex flex-col items-center mb-8">
          <Text type="h4" as="h3" className="mt-4 mb-8">
            {text()}
          </Text>
        </div>
        <div className="flex flex-col md:flex-row justify-center items-center text-center mb-8 gap-4">
          <div className="w-[250px]">
            <img
              className="w-[250px] h-[250px] object-cover rounded-full"
              width="250px"
              height="250px"
              src="/team/mike_van_houtum.webp"
              alt="Mike van Houtum"
            />
            <Text type="h4">Mike van Houtum</Text>
            <Text type="h6" as="h5">
              {cofounder}
            </Text>
          </div>
          <div className="w-[250px]">
            <img
              className="w-[250px] h-[250px] object-cover rounded-full"
              width="250px"
              height="250px"
              src="/team/maayke_cornelissen.webp"
              alt="Maayke Cornelissen"
            />
            <Text type="h4">Maayke Cornelissen</Text>
            <Text type="h6" as="h5">
              {cofounder}
            </Text>
          </div>
        </div>
        <HorizontalRuler className="mb-8" />
        <div className="pb-8">
          <Text className="mb-6">{text()}</Text>
          <LinkButton to="/meet-your-team">{text()}</LinkButton>
        </div>
      </Container>
      <svg
        className="w-[calc(214%+1.3px)] h-[76px] -scale-y-100 fill-primary"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1000 100"
        preserveAspectRatio="none"
      >
        <path
          d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
	c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
	c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
        ></path>
      </svg>
    </Box>
  );
};

export default HomeMeetTheFounders;
