import { FC } from "react";
import Text from "../../../Widgets/Text/Text";
import { HouseForSaleImage } from "../types";
import HouseImageComponent from "./HouseImageComponent";

export type ImageBoxProps = {
  images: HouseForSaleImage[];
  houseName: string;
  onImageClick?: (index: number) => void;
};

const ImageBox: FC<ImageBoxProps> = ({ images, houseName, onImageClick }) => {
  switch (images.length) {
    case 0:
      return (
        <div className="w-full h-[50vh] bg-grey-ultra-light border-grey-light flex flex-col items-center justify-center">
          <Text>There are no images for this property.</Text>
        </div>
      );
    case 1:
      return (
        <HouseImageComponent
          className="h-[50vh]"
          {...images[0]}
          houseName={houseName}
          onClick={() => onImageClick?.(0)}
        />
      );
    case 2:
      return (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
          {images.map((image, i) => (
            <HouseImageComponent
              key={`image-${i}`}
              className="h-[50vh]"
              {...image}
              houseName={houseName}
              onClick={() => onImageClick?.(i)}
            />
          ))}
        </div>
      );
    case 3:
      return (
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-2">
          <HouseImageComponent
            {...images[0]}
            className="lg:h-[50vh] lg:col-span-2"
            houseName={houseName}
            onClick={() => onImageClick?.(0)}
          />
          <div className="hidden lg:h-[50vh] lg:grid grid-cols-1 gap-2">
            {images.slice(1).map((image, i) => (
              <HouseImageComponent
                key={`image-${i}`}
                {...image}
                className="lg:h-[calc(25vh-0.25rem)]"
                houseName={houseName}
                onClick={() => onImageClick?.(i + 1)}
              />
            ))}
          </div>
        </div>
      );
    case 4:
      return (
        <div className="grid grid-cols-1 lg:grid-cols-5 gap-2">
          <HouseImageComponent
            {...images[0]}
            className="lg:h-[50vh] lg:col-span-3"
            houseName={houseName}
            onClick={() => onImageClick?.(0)}
          />
          <div className="hidden lg:grid grid-cols-1 lg:grid-cols-2 lg:col-span-2 gap-2">
            {images.slice(1, -1).map((image, i) => (
              <HouseImageComponent
                key={`image-${i}`}
                {...image}
                className="lg:h-[calc(25vh-0.25rem)]"
                houseName={houseName}
                onClick={() => onImageClick?.(i + 1)}
              />
            ))}
            <HouseImageComponent
              key={`image-3`}
              {...images[3]}
              className="lg:h-[calc(25vh-0.25rem)] lg:col-span-2"
              houseName={houseName}
              onClick={() => onImageClick?.(3)}
            />
          </div>
        </div>
      );
    default:
      return (
        <div className="grid grid-cols-1 lg:grid-cols-5 gap-2">
          <HouseImageComponent
            {...images[0]}
            className="lg:h-[50vh] lg:col-span-3"
            houseName={houseName}
            onClick={() => onImageClick?.(0)}
          />
          <div className="hidden lg:grid grid-cols-2 lg:col-span-2 gap-2">
            {images.slice(1, 5).map((image, i) => (
              <HouseImageComponent
                key={`image-${i}`}
                {...image}
                className="lg:h-full"
                houseName={houseName}
                onClick={() => onImageClick?.(i + 1)}
              />
            ))}
          </div>
        </div>
      );
  }
};
export default ImageBox;
