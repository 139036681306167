import React from 'react';
import Slider from 'react-slick';

const Card = ({ title, images, description, buttonText, baths, beds, country, currency, town }) => {
  // Log the images prop to debug

//   let slider=images;
 let sliderImage=images.images.image;
 console.log(sliderImage);
  // Settings for the carousel
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="card" style={{ width:'30%' , margin: '10px', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}>
             
            <img
              src={sliderImage[0].url} // Access the URL property
              alt={title}
              style={{
                width: "100%",
                height: "200px",
                objectFit: "cover",
              }}
            />
          
      <h2>{title}</h2>
      {/* <p>{description}</p> */}
      <p><strong>{beds} Beds</strong></p>
      <p><strong>{baths} Baths</strong></p>
      <p><strong>Location:</strong> {town}, {country}</p>
      <p><strong>Price:</strong> {currency}</p>
      <button>{buttonText}</button>
    </div>
  );
};

export default Card;
