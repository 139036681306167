import { FC, useEffect, useRef, useState } from "react";
import { AllLanguages, Language } from "./Page";
import { classNames } from "../utils/string";
import { Icon } from "@iconify/react";
import caretDown from "@iconify-icons/ph/caret-down";
import caretUp from "@iconify-icons/ph/caret-up";
export type LanguageDropdownProps = {
  language: Language;
  setLanguage: (language: Language) => void;
};

const altNames = {
  en: "English",
  es: "Español",
  nl: "Nederlands",
  de: "Deutsch"
};

const LanguageDropdown: FC<LanguageDropdownProps> = ({ language, setLanguage }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [positionY, setPositionY] = useState(0);
  useEffect(() => {
    const onDocumentClick = () => {
      setOpen(false);
    };
    document.addEventListener("click", onDocumentClick);
    return () => document.removeEventListener("click", onDocumentClick);
  }, []);
  useEffect(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      setPositionY(rect.height + 10);
    }
  }, [ref.current]);
  return (
    <div className="relative ml-4 mt-[3.25rem]" ref={ref}>
      <div
        className="p-2 bg-white rounded-full shadow flex flex-row items-center gap-2 cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!open);
        }}
      >
        <img
          src={`/visuals/${language}-svg.png`}
          alt={altNames[language]}
          width="32px"
          height="32px"
          className="w-8 h-8"
        />
        <Icon icon={open ? caretUp : caretDown} className="w-5 h-5" />
      </div>
      {open && (
        <div className="absolute bg-white p-2 shadow-lg rounded-full space-y-3" style={{ top: positionY }}>
          {AllLanguages.map((lan) => (
            <img
              onClick={() => setLanguage(lan)}
              src={`/visuals/${lan}-svg.png`}
              alt={altNames[lan]}
              width="32px"
              height="32px"
              className={classNames("w-8 h-8", language === lan && "opacity-50")}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageDropdown;
