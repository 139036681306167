import { FC } from "react";
import Page from "../../Layout/Page";
import BuyingAgentCallToAction from "./BuyingAgentCallToAction";
import BuyingAgentIntro from "./BuyingAgentIntro";
import BuyingAgentNeedToKnow from "./BuyingAgentNeedToKnow";
import BuyingAgentWhatWeDo from "./BuyingAgentWhatWeDo";
import BuyingAgentSteps from "./BuyingAgentSteps";
import BuyingAgentNextSteps from "./BuyingAgentNextSteps";
import BuyingAgentConsultation from "./BuyingAgentConsultation";
import BuyingAgentHelp from "./BuyingAgentHelp";
import BuyingAgentHowItWorks from "./BuyingAgentHowItWorks";
import SEO from "../../Widgets/SEO/SEO";

export type BuyingAgentProps = {};

const seo = {
  title: {
    en: "Your purchase agent in Spain",
    es: "Su agente de compra en España",
    nl: "Uw aankoopmakelaar in Spanje",
    de: "Ihr Kaufagent in Spanien"
  },
  description: {
    en: "Owning a second home in beautiful Spain, what more could you want? Buying a house in Spain has many advantages. You can go there several times a year to relax and when you are not there you can rent out your house and earn money. We work together with well-known real estate agencies in the Axarquia.",
    es: "Ser propietario de una segunda vivienda en la hermosa España, ¿qué más se puede pedir? Comprar una casa en España tiene muchas ventajas. Puede ir allí varias veces al año para relajarse y cuando no esté allí puede alquilar su casa y ganar dinero. Trabajamos junto con conocidas agencias inmobiliarias en la Axarquía.",
    nl: "Een tweede huis bezitten in het prachtige Spanje, wat wil je nog meer? Het kopen van een huis in Spanje heeft vele voordelen. U kunt er meerdere keren per jaar heen om te ontspannen en wanneer u er niet bent kunt u uw huis verhuren en geld verdienen. We werken samen met bekende makelaars in de Axarquia.",
    de: "Ein Zweitwohnsitz im schönen Spanien - was will man mehr? Ein Haus in Spanien zu kaufen hat viele Vorteile. Sie können mehrmals im Jahr dorthin fahren, um sich zu entspannen, und wenn Sie nicht dort sind, können Sie Ihr Haus vermieten und Geld verdienen. Wir arbeiten mit bekannten Immobilienmaklern in der Axarquia zusammen."
  }
};

const BuyingAgent: FC<BuyingAgentProps> = ({}) => {
  return (
    <Page>
      <SEO title={seo.title} description={seo.description}>
        <BuyingAgentCallToAction />
        <BuyingAgentIntro />
        <BuyingAgentNeedToKnow />
        <BuyingAgentWhatWeDo />
        <BuyingAgentSteps />
        <BuyingAgentNextSteps />
        <BuyingAgentConsultation />
        <BuyingAgentHelp />
        <BuyingAgentHowItWorks />
      </SEO>
    </Page>
  );
};

export default BuyingAgent;
