import { generateTextClassName } from "../Text";
import { TextType } from "../types";
import { Editor as TinyMCEEditor } from "tinymce";
import { getButtonPlugin } from "./plugin.button";
import { getHrPlugin } from "./plugin.hr";

const regex = new RegExp(/<(p|h1|h2|h3|h4|h5|h6)>/g);

export const joinStrings = (separator: string, ...values: (string | undefined | null | false)[]) => {
  return values
    .filter((v) => v)
    .filter((v) => String(v).trim().length)
    .join(separator);
};

export const joinStringGroups = (
  separator: string,
  groupSeparator: string,
  ...values: (string | undefined | null | false)[][]
) => {
  return joinStrings(groupSeparator, ...values.map((v) => joinStrings(separator, ...v)));
};

export const addTextClasses = (content = ""): string => {
  const matches = Array.from(content.matchAll(regex)).reverse();
  return (
    matches?.reduce((result, match) => {
      const index = Number(match.index ?? 0);
      const length = match[1].length;
      const leading = result.substring(0, index + length + 1);
      const className = generateTextClassName(match[1] as TextType, "");
      const trailing = result.substring(index + length + 1);
      return `${leading} className="${className}"${trailing}`;
    }, content) ?? content
  );
};

export const setupCustomTinyMCEPlugins = (editor: TinyMCEEditor) => {
  editor.ui.registry.addButton("button", getButtonPlugin(editor));
  editor.ui.registry.addButton("hr", getHrPlugin(editor));
  editor.ui.registry.addGroupToolbarButton("decoration", {
    icon: "bold",
    tooltip: "Text Decoration",
    items: "bold italic underline strikethrough"
  });
  editor.ui.registry.addGroupToolbarButton("alignment", {
    icon: "align-justify",
    tooltip: "Alignment",
    items: "alignleft aligncenter alignright alignjustify"
  });
  editor.ui.registry.addGroupToolbarButton("lists", {
    icon: "unordered-list",
    tooltip: "Lists",
    items: "numlist bullist"
  });
};
