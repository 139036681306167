import axios from "axios";
import { useEffect, useState } from "react";

export type GetDataResult<T extends unknown> = [
  loading: boolean,
  data: T | undefined,
  loadData: () => Promise<T | null>
];

const useGetData = <T extends unknown>(endpoint: string, useStorage = true): GetDataResult<T> => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<T | undefined>();

  const loadStoredData = () => {
    const stored = localStorage.getItem(endpoint);
    if (stored) {
      try {
        setData(JSON.parse(stored) as T);
        setLoading(false);
      } catch {}
    }
  };

  useEffect(() => {
    loadData();
    if (useStorage) {
      loadStoredData();
    }
  }, [endpoint]);

  const loadData = async (numTries = 0) => {
    setLoading(true);
    try {
      const { data } = await axios.get<T>(endpoint);
      setData(data);
      setLoading(false);
      if (useStorage) {
        localStorage.setItem(endpoint, JSON.stringify(data));
      }
      return data;
    } catch {
      if (numTries < 2) {
        setLoading(true);
        setTimeout(() => loadData(numTries + 1), 100);
      } else {
        setLoading(false);
      }
      return null;
    }
  };

  return [loading, data, loadData];
};

export default useGetData;
