import { FC, useContext } from "react";
import Box from "../../Layout/Box";
import Container from "../../Layout/Container";
import HorizontalRuler from "../../Widgets/Separators/HorizontalRuler";
import Text from "../../Widgets/Text/Text";
import { Icon, IconifyIcon } from "@iconify/react";
import marketing from "@iconify-icons/ph/chart-line-up-bold";
import calendar from "@iconify-icons/ph/calendar-check-bold";
import callout from "@iconify-icons/ph/headphones";
import llave from "@iconify-icons/ph/key-bold";
import wrench from "@iconify-icons/ph/wrench-bold";
import bathtub from "@iconify-icons/ph/bathtub-bold";
import phone from "@iconify-icons/ph/phone-call-bold";
import { LanguageContext } from "../../Layout/Page";

const translations = {
  en: [
    `Key Holding`,
    `Inspection of the property (`,
    `x per month)`,
    `Detailed report (`,
    `x per month)`,
    `Mail collection & forwarding`,
    `24/7 emergency callout service`,
    `* Callout rate: 15€ per hour`,
    `Callout service (2x per month)`,
    `* Extra callouts: 15€ per hour`,
    `Our Pricing`,
    `Discover our simple & transparent pricing`,
    `We provide an all-inclusive service, covering all aspects of the rental process. We give homeowners peace of mind and ensure that all the areas are taken care of!`,
    `No financial surprises`,
    `We don't like surprises, at least not in the financial area. This is why we aim to be as transparent as possible in our pricing.`,
    `No hidden print`,
    `We offer our services using a minimal agreement. No strings attached, literally. Curious? Please reach out and have us send you a preview agreement.`,
    `Questions?`,
    `If you have any questions, please feel free to read our `,
    `FAQ`,
    ` or `,
    `Contact`,
    ` us directly. Further more you can fill in our form to get an insight in what your property could earn.`,
    `What you will get`,
    `1. Monthly inspections The property management package is created for homeowners that do not wish to rent their house to guests. Our team will visit and rigorously inspect your property using a tailored and thorough property management checklist. This checklist will include a general overview of the property as well as specific items you want to be checked. After each inspection, you will receive a report on your property along with photographic evidence. If needed we coordinate cleaning and/or maintenance teams to make sure everything is in order for your next personal visit.`,
    `2. Service visits Our service visits involve having a member of our team go to the property for a specific task, such as; letting an electrician into the property, checking in on a alarm status, turning on/off electricity fuses. The standard plan includes 1 service visit, the premium plan includes 2 service visits.`,
    `3. Letters and mail handling During visits to your property, our team will open your letterbox to check if any mail has been delivered. Letters will be stored or forwarded onto you by your preference.`,
    `4. Keyholding Keys to your property will be securely held by our team. If needed, anyone visiting, staying or working at your property can easily collect the keys. Following confirmation of who will be collecting the keys from the property owner, the keys can be checked out and returned as often as needed.`,
    `5. Concierge services Our concierge services consist of organizing airport transfers, car rentals, group excursions, boat hire and chef experiences. Let us help make you and your families' stay that extra bit special.`,
    `Standard Plan`,
    `Premium Plan`,
    `Prices per Plan`,
    `Nr. of bedrooms`,
    `Standard Plan`,
    `Premium Plan`,
    `35€ / month`,
    `65€ / month`,
    `50€ / month`,
    `90€ / month`,
    `75€ / month`,
    `140€ / month`,
    `100€ / month`,
    `185€ / month`,
    `125€ / month`,
    `230€ / month`,
    `150€ / month`,
    `280€ / month`,
    `7 (or more)`,
    `Upon inquiry`,
    `Upon inquiry`
  ],
  es: [
    `Sujeción de llaves`,
    `Inspección de la propiedad (`,
    `x por mes)`,
    `Informe detallado (`,
    `x por mes)`,
    `Recolección y reenvío de correo`,
    `Servicio de llamada de emergencia 24/7`,
    `* Tarifa de llamada: 15 € por hora`,
    `Servicio de llamada (2x por mes)`,
    `* Llamadas adicionales: 15 € por hora`,
    `Nuestros precios`,
    `Descubra nuestra sencilla y transparente política de precios`,
    `Ofrecemos un servicio todo incluido que cubre todos los aspectos del proceso de alquiler. ¡Damos a los propietarios la tranquilidad y nos aseguramos de que todas las áreas estén bien cuidadas!`,
    `Sin sorpresas financieras`,
    `No nos gustan las sorpresas, al menos no en el área financiera. Es por eso que nuestro objetivo es ser lo más transparentes posible en nuestros precios.`,
    `Sin letra pequeña`,
    `Ofrecemos nuestros servicios utilizando un acuerdo mínimo. Sin compromisos, literalmente. ¿Curioso? Por favor, póngase en contacto con nosotros y le enviaremos un acuerdo preliminar.`,
    `¿Preguntas?`,
    `Si tiene alguna pregunta, no dude en leer nuestras `,
    `FAQ`,
    ` o `,
    `Contacto`,
    ` directamente. Además, puede completar nuestro formulario para obtener una idea de lo que podría ganar su propiedad.`,
    `Lo que obtendrá`,
    `1. Inspecciones mensuales El paquete de gestión de propiedades está creado para propietarios que no desean alquilar su casa a huéspedes. Nuestro equipo visitará e inspeccionará rigurosamente su propiedad utilizando una lista de verificación de gestión de propiedades adaptada y exhaustiva. Esta lista incluirá una descripción general de la propiedad, así como elementos específicos que desea que se revisen. Después de cada inspección, recibirá un informe sobre su propiedad junto con pruebas fotográficas. Si es necesario, coordinamos equipos de limpieza y / o mantenimiento para asegurarnos de que todo esté en orden para su próxima visita personal.`,
    `2. Visitas de servicio Nuestras visitas de servicio implican que un miembro de nuestro equipo vaya a la propiedad para una tarea específica, como; dejar entrar a un electricista en la propiedad, verificar el estado de una alarma, encender / apagar los fusibles de electricidad. El plan estándar incluye 1 visita de servicio, el plan premium incluye 2 visitas de servicio.`,
    `3. Manejo de cartas y correo Durante las visitas a su propiedad, nuestro equipo abrirá su buzón para verificar si se ha entregado algún correo. Las cartas se almacenarán o se le enviarán según su preferencia.`,
    `4. Sujeción de llaves Las llaves de su propiedad serán retenidas de forma segura por nuestro equipo. Si es necesario, cualquier persona que visite, se aloje o trabaje en su propiedad puede recoger fácilmente las llaves. Después de confirmar quién recogerá las llaves del propietario de la propiedad, las llaves se pueden registrar y devolver tantas veces como sea necesario.`,
    `5. Servicios de conserjería Nuestros servicios de conserjería consisten en organizar traslados al aeropuerto, alquiler de coches, excursiones en grupo, alquiler de barcos y experiencias de chef. Permítanos ayudarlo a que usted y su familia se queden un poco más especiales.`,
    `Plan estándar`,
    `Plan premium`,
    `Precios por plan`,
    `Nr. de habitaciones`,
    `Plan estándar`,
    `Plan premium`,
    `35€ / mes`,
    `65€ / mes`,
    `50€ / mes`,
    `90€ / mes`,
    `75€ / mes`,
    `140€ / mes`,
    `100€ / mes`,
    `185€ / mes`,
    `125€ / mes`,
    `230€ / mes`,
    `150€ / mes`,
    `280€ / mes`,
    `7 (o más)`,
    `Previa solicitud`,
    `Previa solicitud`
  ],
  nl: [
    `Sleutelbeheer`,
    `Inspectie van het pand (`,
    `x per maand)`,
    `Gedetailleerd rapport (`,
    `x per maand)`,
    `Postverzameling & doorsturen`,
    `24/7 noodoproep`,
    `* Oproeptarief: 15€ per uur`,
    `Oproepdienst (2x per maand)`,
    `* Extra oproepen: 15€ per uur`,
    `Onze prijzen`,
    `Ontdek onze eenvoudige en transparante prijzen`,
    `Wij bieden een all-inclusive service die alle aspecten van het verhuurproces omvat. We geven huiseigenaren gemoedsrust en zorgen ervoor dat alle gebieden worden verzorgd!`,
    `Geen financiële verrassingen`,
    `We houden niet van verrassingen, in ieder geval niet op financieel gebied. Daarom streven we ernaar om zo transparant mogelijk te zijn in onze prijzen.`,
    `Geen verborgen print`,
    `We bieden onze diensten aan met behulp van een minimaal contract. Geen verplichtingen, letterlijk. Benieuwd? Neem contact met ons op en laat ons u een voorbeeldcontract sturen.`,
    `Vragen?`,
    `Als u vragen heeft, lees dan gerust onze `,
    `FAQ`,
    ` of `,
    `Contact`,
    ` ons rechtstreeks. Verder kunt u ons formulier invullen om inzicht te krijgen in wat uw eigendom zou kunnen verdienen.`,
    `Wat u krijgt`,
    `1. Maandelijkse inspecties Het pakket voor het beheer van onroerend goed is gemaakt voor huiseigenaren die hun huis niet aan gasten willen verhuren. Ons team zal uw eigendom bezoeken en grondig inspecteren met behulp van een op maat gemaakte en grondige checklist voor het beheer van onroerend goed. Deze checklist bevat een algemeen overzicht van het pand en specifieke items die u wilt laten controleren. Na elke inspectie ontvangt u een rapport over uw eigendom samen met fotografisch bewijs. Indien nodig coördineren we schoonmaak- en / of onderhoudsteams om ervoor te zorgen dat alles in orde is voor uw volgende persoonlijke bezoek.`,
    `2. Servicebezoeken Onze servicebezoeken omvatten dat een lid van ons team naar het pand gaat voor een specifieke taak, zoals; een elektricien binnenlaten in het pand, inchecken op een alarmstatus, elektriciteitszekeringen aan- of uitzetten. Het standaardplan omvat 1 servicebezoek, het premiumplan omvat 2 servicebezoeken.`,
    `3. Brieven en postverwerking Tijdens bezoeken aan uw eigendom zal ons team uw brievenbus openen om te controleren of er post is bezorgd. Brieven worden opgeslagen of naar u doorgestuurd naar uw voorkeur.`,
    `4. Sleutelbeheer Sleutels van uw eigendom worden veilig bewaard door ons team. Indien nodig kan iedereen die uw eigendom bezoekt, verblijft of werkt, de sleutels gemakkelijk ophalen. Na bevestiging van wie de sleutels van de eigenaar van het pand zal ophalen, kunnen de sleutels zo vaak als nodig worden ingecheckt en geretourneerd.`,
    `5. Concierge-diensten Onze conciërgediensten bestaan uit het organiseren van luchthaventransfers, autoverhuur, groepsuitstapjes, bootverhuur en chef-ervaringen. Laat ons u helpen uw verblijf en dat van uw gezin net dat beetje specialer te maken.`,
    `Standaardplan`,
    `Premiumplan`,
    `Prijzen per plan`,
    `Aantal slaapkamers`,
    `Standaardplan`,
    `Premiumplan`,
    `35€ / maand`,
    `65€ / maand`,
    `50€ / maand`,
    `90€ / maand`,
    `75€ / maand`,
    `140€ / maand`,
    `100€ / maand`,
    `185€ / maand`,
    `125€ / maand`,
    `230€ / maand`,
    `150€ / maand`,
    `280€ / maand`,
    `7 (of meer)`,
    `Op aanvraag`,
    `Op aanvraag`
  ],
  de: [
    `Schlüsselverwaltung`,
    `Inspektion des Eigentums (`,
    `x pro Monat)`,
    `Detaillierter Bericht (`,
    `x pro Monat)`,
    `Post- und Postbearbeitung`,
    `24/7 Notruf`,
    `* Callout-Rate: 15€ pro Stunde`,
    `Callout-Service (2x pro Monat)`,
    `* Zusätzliche Anrufe: 15€ pro Stunde`,
    `Unsere Preisgestaltung`,
    `Entdecken Sie unsere einfache und transparente Preisgestaltung`,
    `Wir bieten einen All-Inclusive-Service, der alle Aspekte des Vermietungsprozesses abdeckt. Wir geben den Hausbesitzern die Sicherheit und sorgen dafür, dass alle Bereiche abgedeckt sind!`,
    `Keine finanziellen Überraschungen`,
    `Wir mögen keine Überraschungen, zumindest nicht im finanziellen Bereich. Deshalb streben wir danach, in unserer Preisgestaltung so transparent wie möglich zu sein.`,
    `Kein versteckter Druck`,
    `Wir bieten unsere Dienstleistungen mit einem minimalen Vertrag an. Keine Verpflichtungen, wörtlich. Neugierig? Bitte kontaktieren Sie uns und lassen Sie uns Ihnen einen Vorvertrag zusenden.`,
    `Fragen?`,
    `Wenn Sie Fragen haben, lesen Sie bitte unsere `,
    `FAQ`,
    ` oder `,
    `Kontakt`,
    ` uns direkt. Darüber hinaus können Sie unser Formular ausfüllen, um einen Einblick zu erhalten, was Ihre Immobilie verdienen könnte.`,
    `Was Sie bekommen werden`,
    `1. Monatliche Inspektionen Das Immobilienmanagement-Paket ist für Hausbesitzer gedacht, die ihr Haus nicht an Gäste vermieten möchten. Unser Team wird Ihr Eigentum besuchen und mit Hilfe einer maßgeschneiderten und gründlichen Checkliste für das Immobilienmanagement Ihr Eigentum gründlich inspizieren. Diese Checkliste enthält einen allgemeinen Überblick über die Immobilie sowie spezifische Elemente, die Sie überprüfen möchten. Nach jeder Inspektion erhalten Sie einen Bericht über Ihre Immobilie zusammen mit fotografischen Beweisen. Bei Bedarf koordinieren wir Reinigungs- und / oder Wartungsteams, um sicherzustellen, dass bei Ihrem nächsten persönlichen Besuch alles in Ordnung ist.`,
    `2. Servicebesuche Unsere Servicebesuche beinhalten, dass ein Mitglied unseres Teams für eine bestimmte Aufgabe zum Objekt geht, z. B. einen Elektriker in das Objekt zu lassen, den Alarmstatus zu überprüfen, Sicherungen für Strom ein- oder auszuschalten. Der Standardplan umfasst 1 Servicebesuch, der Premiumplan umfasst 2 Servicebesuche.`,
    `3. Briefe und Postbearbeitung Während der Besuche in Ihrem Eigentum öffnet unser Team Ihren Briefkasten, um zu überprüfen, ob Post zugestellt wurde. Briefe werden nach Ihrer Wahl gespeichert oder an Sie weitergeleitet.`,
    `4. Schlüsselverwaltung Schlüssel zu Ihrem Eigentum werden sicher von unserem Team aufbewahrt. Wenn nötig, kann jeder, der Ihr Eigentum besucht, übernachtet oder arbeitet, die Schlüssel problemlos abholen. Nach Bestätigung, wer die Schlüssel vom Eigentümer des Grundstücks abholt, können die Schlüssel so oft wie nötig eingecheckt und zurückgegeben werden.`,
    `5. Concierge-Dienste Unsere Concierge-Dienste bestehen aus der Organisation von Flughafentransfers, Autovermietungen, Gruppenausflügen, Bootsverleih und Chef-Erlebnissen. Lassen Sie uns Ihnen helfen, Ihren Aufenthalt und den Ihrer Familie noch etwas Besonderes zu machen.`,
    `Standardplan`,
    `Premiumplan`,
    `Preise pro Plan`,
    `Anzahl der Schlafzimmer`,
    `Standardplan`,
    `Premiumplan`,
    `35€ / Monat`,
    `65€ / Monat`,
    `50€ / Monat`,
    `90€ / Monat`,
    `75€ / Monat`,
    `140€ / Monat`,
    `100€ / Monat`,
    `185€ / Monat`,
    `125€ / Monat`,
    `230€ / Monat`,
    `150€ / Monat`,
    `280€ / Monat`,
    `7 (oder mehr)`,
    `Auf Anfrage`,
    `Auf Anfrage`
  ]
};

type ItemProps = {
  label: string;
  icon: IconifyIcon;
  isLast?: boolean;
};

const Item: FC<ItemProps> = ({ label, icon, isLast }) => (
  <>
    <div className="p-2 flex flex-row items-center">
      <Icon icon={icon} className="text-primary w-6 h-6 mr-2" />
      <Text>{label}</Text>
    </div>
    {!isLast && <HorizontalRuler />}
  </>
);

const getItems = (timesPerMonth: number, text: () => string): ItemProps[] => [
  { label: text(), icon: llave },
  { label: `${text()}${timesPerMonth}${text()}`, icon: bathtub },
  { label: `${text()}${timesPerMonth}${text()}`, icon: calendar },
  { label: text(), icon: marketing },
  { label: text(), icon: wrench }
];

export type PropertyTransparentPricingProps = {};

const PropertyTransparentPricing: FC<PropertyTransparentPricingProps> = ({}) => {
  const { language } = useContext(LanguageContext);
  let next = 0;
  const text = () => translations[language][next++] ?? "";
  const sharedItems = getItems(0, text);
  const standardItems = sharedItems
    .map((it) => ({ ...it, label: it.label.replace("0", "1") }))
    .concat({ label: text(), icon: callout });
  const premiumItems = sharedItems
    .map((it) => ({ ...it, label: it.label.replace("0", "2") }))
    .concat({ label: text(), icon: phone }, { label: text(), icon: callout });

  return (
    <>
      <Box backgroundColor="secondary">
        <Container>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
            <div className="text-white px-8 py-4 my-4 md:col-span-2 lg:my-0 grid lg:grid-cols-2">
              <Text className="lg:col-span-2" type="h2">
                {text()}
              </Text>
              <div className="p-4">
                <Text type="h4">{text()}</Text>
                <Text>{text()}</Text>
              </div>
              <div className="p-4">
                <Text type="h4">{text()}</Text>
                <Text>{text()}</Text>
              </div>
              <div className="p-4">
                <Text type="h4">{text()}</Text>
                <Text>{text()}</Text>
              </div>
              <div className="p-4">
                <Text type="h4">{text()}</Text>
                <Text>
                  {text()}
                  <a href="/faq" target="_self" className="text-white hover:text-secondary underline">
                    {text()}
                  </a>
                  {text()}
                  <a href="/contact" target="_self" className="text-white hover:text-secondary underline">
                    {text()}
                  </a>
                  {text()}
                </Text>
              </div>
            </div>
          </div>
        </Container>
        <svg
          className="w-[calc(214%+1.3px)] h-[76px] -scale-y-100 fill-white"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path
            d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
	c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
	c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
          ></path>
        </svg>
      </Box>
      <Box>
        <Container>
          <div>
            <Text type="h2">{text()}</Text>
            <div className="text-left py-4 mb-4">
              <Text className="pb-4">{text()}</Text>
              <Text className="pb-4">{text()}</Text>
              <Text className="pb-4">{text()}</Text>
              <Text className="pb-4">{text()}</Text>
              <Text className="pb-4">{text()}</Text>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 py-8">
            <div className="bg-white shadow rounded md:rounded-r-none lg:rounded-r overflow-hidden w-fit mx-auto my-4 lg:my-0">
              <div className="bg-primary px-8 py-4 text-white">
                <Text type="h2">{text()}</Text>
              </div>
              <div className="px-8 py-4">
                <div className="text-left">
                  {standardItems.map((item, i) => (
                    <Item key={`item-${i}`} {...item} isLast={i === standardItems.length - 1} />
                  ))}
                </div>
              </div>
            </div>
            <div className="bg-white shadow rounded md:rounded-l-none lg:rounded-l overflow-hidden w-fit mx-auto my-4 lg:my-0">
              <div className="bg-primary px-8 py-4 text-white">
                <Text type="h2">{text()}</Text>
              </div>
              <div className="px-8 py-4">
                <div className="text-left">
                  {premiumItems.map((item, i) => (
                    <Item key={`item-${i}`} {...item} isLast={i === premiumItems.length - 1} />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <Text className="mt-8" type="h2">
            {text()}
          </Text>
          <div className="text-left shadow my-8">
            <div className="grid grid-cols-3 odd:bg-grey-light even:bg-white">
              <Text className="mx-4" type="h4">
                {text()}
              </Text>
              <Text className="mx-4" type="h4">
                {text()}
              </Text>
              <Text className="mx-4" type="h4">
                {text()}
              </Text>
            </div>

            <div className="grid grid-cols-3 odd:bg-grey-light even:bg-white">
              <Text className="mx-4" type="h4">
                1
              </Text>
              <Text className="mx-4" type="h4">
                {text()}
              </Text>
              <Text className="mx-4" type="h4">
                {text()}
              </Text>
            </div>
            <div className="grid grid-cols-3 odd:bg-grey-light even:bg-white">
              <Text className="mx-4" type="h4">
                2
              </Text>
              <Text className="mx-4" type="h4">
                {text()}
              </Text>
              <Text className="mx-4" type="h4">
                {text()}
              </Text>
            </div>
            <div className="grid grid-cols-3 odd:bg-grey-light even:bg-white">
              <Text className="mx-4" type="h4">
                3
              </Text>
              <Text className="mx-4" type="h4">
                {text()}
              </Text>
              <Text className="mx-4" type="h4">
                {text()}
              </Text>
            </div>
            <div className="grid grid-cols-3 odd:bg-grey-light even:bg-white">
              <Text className="mx-4" type="h4">
                4
              </Text>
              <Text className="mx-4" type="h4">
                {text()}
              </Text>
              <Text className="mx-4" type="h4">
                {text()}
              </Text>
            </div>
            <div className="grid grid-cols-3 odd:bg-grey-light even:bg-white">
              <Text className="mx-4" type="h4">
                5
              </Text>
              <Text className="mx-4" type="h4">
                {text()}
              </Text>
              <Text className="mx-4" type="h4">
                {text()}
              </Text>
            </div>
            <div className="grid grid-cols-3 odd:bg-grey-light even:bg-white">
              <Text className="mx-4" type="h4">
                6
              </Text>
              <Text className="mx-4" type="h4">
                {text()}
              </Text>
              <Text className="mx-4" type="h4">
                {text()}
              </Text>
            </div>
            <div className="grid grid-cols-3 odd:bg-grey-light even:bg-white">
              <Text className="mx-4" type="h4">
                {text()}
              </Text>
              <Text className="mx-4" type="h4">
                {text()}
              </Text>
              <Text className="mx-4" type="h4">
                {text()}
              </Text>
            </div>
          </div>
        </Container>
      </Box>
    </>
  );
};

export default PropertyTransparentPricing;
