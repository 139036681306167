import { FC, useContext } from "react";
import Box from "../../Layout/Box";
import Container from "../../Layout/Container";
import Text from "../../Widgets/Text/Text";
import LinkButton from "../../Widgets/Button/LinkButton";
import { LanguageContext } from "../../Layout/Page";

export type HomeBookCarProps = {};

const translations = {
  en: [
    `Need a rental car from Malaga Airport?`,
    `As a service to our customers we offer a direct link to one of our trusted partners.`,
    `Click here to rent a car`
  ],
  es: [
    `¿Necesita un coche de alquiler desde el aeropuerto de Málaga?`,
    `Como servicio a nuestros clientes, ofrecemos un enlace directo a uno de nuestros socios de confianza.`,
    `Haga clic aquí para alquilar un coche`
  ],
  nl: [
    `Een huurauto nodig vanaf de luchthaven van Malaga?`,
    `Als service aan onze klanten bieden wij een directe link naar een van onze vertrouwde partners.`,
    `Klik hier om een auto te huren`
  ],
  de: [
    `Brauchen Sie einen Mietwagen vom Flughafen Malaga?`,
    `Als Service für unsere Kunden bieten wir einen direkten Link zu einem unserer vertrauenswürdigen Partner an.`,
    `Klicken Sie hier, um ein Auto zu mieten`
  ]
};
const HomeBookCar: FC<HomeBookCarProps> = ({}) => {
  const { language } = useContext(LanguageContext);
  let next = 0;
  const text = () => translations[language][next++] ?? "";
  return (
    <Box backgroundColor="white">
      <Container>
        <Text className="mt-8" type="h2">
          {text()}
        </Text>
        <div className="flex flex-col items-center mb-8">
          <img
            className="mt-8"
            width="376px"
            height="84px"
            src="/cars.webp"
            alt="Some of the cars you can rent via discovercars.com"
          />
          <Text className="mt-8">{text()}</Text>
        </div>
        <LinkButton
          target="_blank"
          to="https://www.discovercars.com/spain/malaga/agp?a_aid=freshpropertymanagement&currency=eur"
        >
          {text()}
        </LinkButton>
      </Container>
    </Box>
  );
};

export default HomeBookCar;
