import axios, { isAxiosError } from "axios";
import { FC, useContext, useState } from "react";
import { GoogleReCaptcha, GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useForm } from "react-hook-form";
import Box from "../../Layout/Box";
import Container from "../../Layout/Container";
import Overlay from "../../Layout/Overlay";
import { LanguageContext } from "../../Layout/Page";
import { classNames } from "../../utils/string";
import Loader from "../../Widgets/Loader";
import HorizontalRuler from "../../Widgets/Separators/HorizontalRuler";
import Text from "../../Widgets/Text/Text";
import SEO from "../../Widgets/SEO/SEO";

export type ContactFormState = "idle" | "sending" | "sent";
export type ContactProps = {};

const translations = {
  en: [
    "You can ",
    "reach out",
    " to us",
    "For any question, please contact us. This can be done via the contact form or whatsapp. We will contact you within 24 hours. We speak English, Dutch, Spanish and German. Please note that on every page you can click on the Whatsapp logo to chat with us.",
    "Phone",
    "Email",
    "Name",
    "Please enter your name",
    "Email",
    "Please enter your email",
    "Message",
    "Please enter your message",
    "Send",
    "Thank you!",
    "We have received your email.",
    "We will respond within 2 working days.",
    "(Click to close this message)"
  ],
  es: [
    "Puedes ",
    "contactarnos",
    " a nosotros",
    "Para cualquier pregunta, contáctenos. Esto se puede hacer a través del formulario de contacto o whatsapp. Nos pondremos en contacto con usted dentro de las 24 horas. Hablamos inglés, holandés, español y alemán. Tenga en cuenta que en cada página puede hacer clic en el logotipo de Whatsapp para chatear con nosotros.",
    "Teléfono",
    "Correo electrónico",
    "Nombre",
    "Por favor ingrese su nombre",
    "Correo electrónico",
    "Por favor ingrese su correo electrónico",
    "Mensaje",
    "Por favor ingrese su mensaje",
    "Enviar",
    "¡Gracias!",
    "Hemos recibido tu correo electrónico.",
    "Responderemos dentro de los 2 días hábiles.",
    "(Haga clic para cerrar este mensaje)"
  ],
  nl: [
    "Je kunt ",
    "contact",
    " met ons opnemen",
    "Voor vragen kun je contact met ons opnemen. Dit kan via het contactformulier of whatsapp. Wij nemen binnen 24 uur contact met u op. Wij spreken Engels, Nederlands, Spaans en Duits. Let op: op elke pagina kun je op het Whatsapp-logo klikken om met ons te chatten.",
    "Telefoon",
    "E-mail",
    "Naam",
    "Vul je naam in",
    "E-mail",
    "Vul je e-mailadres in",
    "Bericht",
    "Vul je bericht in",
    "Verzenden",
    "Bedankt!",
    "We hebben je e-mail ontvangen.",
    "We zullen binnen 2 werkdagen reageren.",
    "(Klik om dit bericht te sluiten)"
  ],
  de: [
    "Sie können ",
    "Kontakt",
    " mit uns aufnehmen",
    "Bei Fragen können Sie uns kontaktieren. Dies kann über das Kontaktformular oder Whatsapp erfolgen. Wir werden uns innerhalb von 24 Stunden mit Ihnen in Verbindung setzen. Wir sprechen Englisch, Niederländisch, Spanisch und Deutsch. Bitte beachten Sie, dass Sie auf jeder Seite auf das Whatsapp-Logo klicken können, um mit uns zu chatten.",
    "Telefon",
    "E-Mail",
    "Name",
    "Bitte geben Sie Ihren Namen ein",
    "E-Mail",
    "Bitte geben Sie Ihre E-Mail-Adresse ein",
    "Nachricht",
    "Bitte geben Sie Ihre Nachricht ein",
    "Senden",
    "Danke!",
    "Wir haben Ihre E-Mail erhalten.",
    "Wir werden innerhalb von 2 Werktagen antworten.",
    "(Klicken Sie hier, um diese Nachricht zu schließen)"
  ]
};

const seo = {
  title: {
    en: "You can reach out to us",
    es: "Puedes contactarnos a nosotros",
    nl: "Je kunt contact met ons opnemen",
    de: "Sie können Kontakt mit uns aufnehmen"
  },
  description: {
    en: "For any question, please contact us. This can be done via the contact form or whatsapp. We will contact you within 24 hours. We speak English, Dutch, Spanish and German. Please note that on every page you can click on the Whatsapp logo to chat with us.",
    es: "Para cualquier pregunta, contáctenos. Esto se puede hacer a través del formulario de contacto o whatsapp. Nos pondremos en contacto con usted dentro de las 24 horas. Hablamos inglés, holandés, español y alemán. Tenga en cuenta que en cada página puede hacer clic en el logotipo de Whatsapp para chatear con nosotros.",
    nl: "Voor vragen kun je contact met ons opnemen. Dit kan via het contactformulier of whatsapp. Wij nemen binnen 24 uur contact met u op. Wij spreken Engels, Nederlands, Spaans en Duits. Let op: op elke pagina kun je op het Whatsapp-logo klikken om met ons te chatten.",
    de: "Bei Fragen können Sie uns kontaktieren. Dies kann über das Kontaktformular oder Whatsapp erfolgen. Wir werden uns innerhalb von 24 Stunden mit Ihnen in Verbindung setzen. Wir sprechen Englisch, Niederländisch, Spanisch und Deutsch. Bitte beachten Sie, dass Sie auf jeder Seite auf das Whatsapp-Logo klicken können, um mit uns zu chatten."
  }
};

const Contact: FC<ContactProps> = ({}) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const { language } = useContext(LanguageContext);
  let next = 0;
  const text = () => translations[language][next++] ?? "";

  const [formState, setFormState] = useState<ContactFormState>("idle");
  const [token, setToken] = useState<string | undefined | null>();
  const onSubmit = async (data: Record<string, unknown>) => {
    try {
      setFormState("sending");
      await axios.post("/contact", { ...data, token });
      setFormState("sent");
    } catch (e) {
      if (isAxiosError(e)) {
        alert(e.stack);
      }
    }
  };

  return (
    <SEO title={seo.title} description={seo.description}>
      <GoogleReCaptchaProvider
        container={{ parameters: { badge: "bottomleft" } }}
        reCaptchaKey="6LeGCX4lAAAAANL_4Rh4wrw0JTsUJvlQvWOyBA80"
        useEnterprise
      >
        <Loader text="Sending..." loading={formState === "sending"}>
          <Box backgroundColor="primary">
            <Container className="text-white">
              <Text type="h1">
                {text()}
                <span className="text-yellow">{text()}</span>
                {text()}
              </Text>
              <Text>{text()}</Text>
              <div className="grid grid-cols-1 md:grid-cols-2 mt-8">
                <div className="flex flex-col">
                  <Text type="h4">{text()}</Text>
                  <a className="hover:text-secondary active:text-secondary" href="tel:+31623700433">
                    +31 6 23700433
                  </a>
                </div>
                <div className="flex flex-col">
                  <Text type="h4">{text()}</Text>
                  <a
                    className="hover:text-secondary active:text-secondary"
                    href="mailto:info@fresh-propertymanagement.com"
                  >
                    info@fresh-propertymanagement.com
                  </a>
                </div>
              </div>
            </Container>
            <svg
              className="w-[calc(214%+1.3px)] h-[76px] -scale-y-100 fill-white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1000 100"
              preserveAspectRatio="none"
            >
              <path
                d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
	c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
	c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
              ></path>
            </svg>
          </Box>
          <Box className="pb-24">
            <Container className="flex flex-col items-stretch max-w-5xl">
              <form
                className="grid grid-cols-1 md:grid-cols-2 w-full text-left mx-auto"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="w-full flex flex-col px-4">
                  <Text type="h5" className="mb-1 text-black">
                    {text()} *
                  </Text>
                  <input
                    {...register("name", { required: true })}
                    className="mb-1 px-4 py-2 rounded border border-grey-regular"
                  />
                  <Text type="h6" className={classNames(errors.name ? "visible" : "invisible", "text-black", "!my-0")}>
                    {text()}
                  </Text>
                </div>
                <div className="w-full flex flex-col px-4">
                  <Text type="h5" className="mb-1 text-black">
                    {text()} *
                  </Text>
                  <input
                    type="email"
                    {...register("email", { required: true })}
                    className="mb-1 px-4 py-2 rounded border border-grey-regular"
                  />
                  <Text type="h6" className={classNames(errors.email ? "visible" : "invisible", "text-black", "!my-0")}>
                    {text()}
                  </Text>
                </div>
                <div className="w-full flex flex-col px-4 col-span-2">
                  <Text type="h5" className="mb-1 text-black">
                    {text()} *
                  </Text>
                  <textarea
                    {...register("message", { required: true })}
                    className="mb-1 px-4 py-2 h-32 rounded border border-grey-regular"
                  />
                  <Text
                    type="h6"
                    className={classNames(errors.message ? "visible" : "invisible", "text-black", "!my-0")}
                  >
                    {text()}
                  </Text>
                </div>
                <GoogleReCaptcha onVerify={setToken} />
                <input
                  disabled={!token}
                  type="submit"
                  value={text()}
                  className={classNames(
                    "mx-4",
                    "px-4",
                    "py-2",
                    "rounded",
                    "bg-primary",
                    "text-white",
                    "fill-white",
                    "text-lg",
                    "hover:scale-105",
                    "active:scale-95",
                    "transition-transform",
                    "col-span-2"
                  )}
                />
              </form>
            </Container>
          </Box>
        </Loader>
        {formState === "sent" && (
          <Overlay
            className="cursor-pointer flex flex-col items-center justify-center bg-grey-super-dark/30"
            onClick={() => setFormState("idle")}
          >
            <div className="bg-white rounded drop-shadow-xl px-8 py-4">
              <Text type="h2">{text()}</Text>
              <HorizontalRuler />
              <Text>
                {text()}
                <br />
                {text()}
              </Text>
              <Text type="h6">{text()}</Text>
            </div>
          </Overlay>
        )}
      </GoogleReCaptchaProvider>
    </SEO>
  );
};

export default Contact;
