import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; // Import useParams
import Button from "../../Widgets/Button/Button";

// Define the types for the property data
interface Property {
  _id: string;
  name: string;
  price: string;
  sizeM2: string;
  mapsLocation: string;
  description: string;
  city: string;
  images: string[];
}

const DetailDevelopment: React.FC = () => {
  const { id } = useParams<{ id: string }>(); // Get the ID from the URL
  const [property, setProperty] = useState<Property | null>(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // State for tracking the current image index

  // Fetch data from the API
  useEffect(() => {
    const fetchProperty = async () => {
      try {
        const response = await fetch(`https://api.fresh-propertymanagement.com/long-term/${id}`);
        const data = await response.json();

        // Set the property data from the response
        if (data) {
          setProperty(data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (id) {
      fetchProperty(); // Fetch the property data only when an id is available
    }
  }, [id]); // Re-run the effect when the id changes

  // Auto-slide functionality
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        property ? (prevIndex + 1) % property.images.length : 0
      );
    }, 3000); // Change image every 1 second

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [property]);

  // If no property data, show a loading message
  if (!property) {
    return <div>Loading...</div>;
  }

  // Navigate to the previous image
  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? property.images.length - 1 : prevIndex - 1
    );
  };

  // Navigate to the next image
  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === property.images.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Manually select an image by clicking on a dot
  const handleDotClick = (index: number) => {
    setCurrentImageIndex(index);
  };

  return (
    <div style={{ maxWidth: "77%", margin: "0 auto", display: "flex", flexDirection: "column", alignItems: "center" }}>
      {/* Left Side: Image Carousel */}
      <div style={{ width: "100%", marginTop: "15px", marginBottom: "20px", position: "relative" }}>
        {/* Carousel Image */}
        <img
          src={`https://api.fresh-propertymanagement.com${property.images[currentImageIndex]}`}
          alt={property.name}
          style={{
            width: "auto",
            height: "auto",
            objectFit: "cover",
            borderRadius: "8px",
          }}
        />

        {/* Previous Button */}
        <button
          onClick={handlePrevImage}
          style={{
            position: "absolute",
            top: "50%",
            left: "10px",
            transform: "translateY(-50%)",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "white",
            border: "none",
            borderRadius: "50%",
            cursor: "pointer",
            zIndex: 1,
            paddingLeft: "15px",    // Corrected padding syntax
            paddingRight: "15px",   // Corrected padding syntax
            paddingTop: "8px",      // Corrected padding syntax
            paddingBottom: "7px"    // Corrected padding syntax
          }}
        >
          &#10094;
        </button>

        {/* Next Button */}
        <button
          onClick={handleNextImage}
          style={{
            position: "absolute",
            top: "50%",
            right: "10px",
            transform: "translateY(-50%)",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "white",
            border: "none",
            borderRadius: "50%",
            cursor: "pointer",
            zIndex: 1,
            paddingLeft: "15px",    // Corrected padding syntax
            paddingRight: "15px",   // Corrected padding syntax
            paddingTop: "8px",      // Corrected padding syntax
            paddingBottom: "7px"    // Corrected padding syntax
          }}
        >
          &#10095;
        </button>

        {/* Carousel Dots */}
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            left: "50%",
            transform: "translateX(-50%)",
            display: "flex",
            gap: "10px",
          }}
        >
          {property.images.map((_, index) => (
            <span
              key={index}
              onClick={() => handleDotClick(index)}
              style={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                backgroundColor: currentImageIndex === index ? "white" : "rgba(255, 255, 255, 0.5)",
                cursor: "pointer",
              }}
            />
          ))}
        </div>
      </div>

      {/* Right Side: Property Details */}
      <div style={{ width: "100%" }}>
        {/* Text Row (Title) */}
        <div style={{ textAlign: "left", margin: "10px 0" }}>
          <h3 style={{ fontSize: "20px", textTransform: "uppercase" }}>
            Property Name: <span style={{ fontSize: "18px" }}>{property.name}</span>
          </h3>
          <h3 style={{ fontSize: "20px", textTransform: "uppercase" }}>
            City Name: <span style={{ fontSize: "18px" }}>{property.city}</span>
          </h3>
          <h3 style={{ fontSize: "20px", textTransform: "uppercase" }}>
            Size m² : <span style={{ fontSize: "18px" }}>{property.sizeM2}</span>
          </h3>
          <h3 style={{ fontSize: "20px", textTransform: "uppercase" }}>
            Price: <span style={{ fontSize: "18px" }}>{property.price}</span>
          </h3>
          {/* Location Sign and Anchor Tag */}
          <div style={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
            <Button
              onClick={() =>
                window.open(property.mapsLocation, "_blank")
              }
            >
              View location
            </Button>
          </div>
        </div>

        {/* Description Section */}
        <div
          style={{
            padding: "10px",
            backgroundColor: "rgb(53, 147, 75)", // Use the desired RGB color here
            borderRadius: "8px",
            marginTop: "20px",
            marginBottom: "20px",
            color: "white"
          }}
        >
          <h3>Description</h3>
          <div dangerouslySetInnerHTML={{ __html: property.description }} />
        </div>
      </div>
    </div>
  );
};

export default DetailDevelopment;
