import { FC, useContext } from "react";
import Box from "../../Layout/Box";
import Container from "../../Layout/Container";
import Text from "../../Widgets/Text/Text";
import { LanguageContext } from "../../Layout/Page";

export type BuyingAgentWhatWeDoProps = {};

const translations = {
  en: [
    "What do we offer?",
    "We are here for you prior to your visit, during viewings, during the negotiation process and after the sale. We work with families and couples who want to make Spain their home, individuals who want to invest in a vacation home, and investment groups who need eyes and ears on the ground to find the best investment opportunities.",
    "We are at your side during the entire purchase process, not only viewing homes, but literally everything involved from A to Z. We work with business partners including lawyers, notaries, gestorias (tax advisors) and an insurance agent. Through our partners we also help you obtain an NIE number. All steps along the way we are there for you."
  ],
  es: [
    "¿Qué ofrecemos?",
    "Estamos aquí para usted antes de su visita, durante las visitas, durante el proceso de negociación y después de la venta. Trabajamos con familias y parejas que quieren hacer de España su hogar, personas que quieren invertir en una casa de vacaciones y grupos de inversión que necesitan ojos y oídos en el terreno para encontrar las mejores oportunidades de inversión.",
    "Estamos a su lado durante todo el proceso de compra, no sólo viendo casas, sino literalmente todo lo que implica de la A a la Z. Trabajamos con socios comerciales, incluyendo abogados, notarios, gestorías (asesores fiscales) y un agente de seguros. A través de nuestros socios también le ayudamos a obtener un número de NIE. Todos los pasos a lo largo del camino estamos ahí para usted."
  ],
  nl: [
    "Wat bieden wij aan?",
    "Wij staan voor u klaar voorafgaand aan uw bezoek, tijdens bezichtigingen, tijdens het onderhandelingsproces en na de verkoop. Wij werken samen met gezinnen en stellen die van Spanje hun thuis willen maken, individuen die willen investeren in een vakantiehuis, en investeringsgroepen die ogen en oren nodig hebben op de grond om de beste investeringsmogelijkheden te vinden.",
    "Wij staan aan uw zijde tijdens het gehele aankoopproces, niet alleen het bezichtigen van woningen, maar letterlijk alles wat erbij komt kijken van A tot Z. Wij werken samen met zakelijke partners waaronder advocaten, notarissen, gestorias (belastingadviseurs) en een verzekeringsagent. Via onze partners helpen wij u ook aan een NIE nummer. Alle stappen onderweg staan wij voor u klaar."
  ],
  de: [
    "Was bieten wir an?",
    "Wir sind für Sie da, bevor Sie uns besuchen, während der Besichtigungen, während des Verhandlungsprozesses und nach dem Verkauf. Wir arbeiten mit Familien und Paaren zusammen, die Spanien zu ihrem Zuhause machen wollen, Einzelpersonen, die in ein Ferienhaus investieren wollen, und Investorengruppen, die vor Ort Augen und Ohren brauchen, um die besten Investitionsmöglichkeiten zu finden.",
    "Wir stehen Ihnen während des gesamten Kaufprozesses zur Seite, nicht nur bei der Besichtigung von Häusern, sondern bei allem, was dazu gehört. Wir arbeiten mit Geschäftspartnern zusammen, darunter Anwälte, Notare, Gestorien (Steuerberater) und einen Versicherungsvertreter. Über unsere Partner helfen wir Ihnen auch bei der Beschaffung einer NIE-Nummer. Alle Schritte auf dem Weg sind wir für Sie da."
  ]
};

const BuyingAgentWhatWeDo: FC<BuyingAgentWhatWeDoProps> = ({}) => {
  const { language } = useContext(LanguageContext);
  let next = 0;
  const text = () => translations[language][next++] ?? "";
  return (
    <Box>
      <Container>
        <Text type="h2">{text()}</Text>
        <Text>{text()}</Text>
        <Text>{text()}</Text>
      </Container>
      <svg
        className="w-[calc(214%+1.3px)] h-[76px] -scale-y-100 fill-primary"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1000 100"
        preserveAspectRatio="none"
      >
        <path
          d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
	c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
	c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
        ></path>
      </svg>
    </Box>
  );
};

export default BuyingAgentWhatWeDo;
