import { FC, PropsWithChildren } from "react";
import { classNames } from "../../utils/string";

export const TextTypes = ["h1", "h2", "h3", "h4", "h5", "h6", "p"] as const;
export type TextType = (typeof TextTypes)[number];

const baseHeaderStyle = "font-unicaone uppercase" as const;

const sizes: Record<TextType, string> = {
  h1: "text-6xl",
  h2: "text-5xl",
  h3: "text-4xl",
  h4: "text-2xl",
  h5: "text-xl",
  h6: "text-lg",
  p: "text-lg"
} as const;

const margins: Record<TextType, string> = {
  h1: "my-4",
  h2: "my-4",
  h3: "my-4",
  h4: "my-4",
  h5: "my-4",
  h6: "my-4",
  p: "my-4"
} as const;

export const generateTextClassName = (type: TextType, className: string): string => {
  return classNames(type !== "p" && baseHeaderStyle, sizes[type], margins[type], className);
};

export type TextProps = PropsWithChildren<{
  type?: TextType;
  as?: TextType;
  className?: string;
  onClick?: () => void;
  dangerouslySetInnerHTML?: { __html: string };
}>;

const Text: FC<TextProps> = ({ type = "p", as = type, className = "", onClick, dangerouslySetInnerHTML, children }) => {
  const cls = generateTextClassName(type, className);
  switch (as) {
    case "h1":
      return (
        <h1 onClick={onClick} className={cls} dangerouslySetInnerHTML={dangerouslySetInnerHTML}>
          {children}
        </h1>
      );
    case "h2":
      return (
        <h2 onClick={onClick} className={cls} dangerouslySetInnerHTML={dangerouslySetInnerHTML}>
          {children}
        </h2>
      );
    case "h3":
      return (
        <h3 onClick={onClick} className={cls} dangerouslySetInnerHTML={dangerouslySetInnerHTML}>
          {children}
        </h3>
      );
    case "h4":
      return (
        <h4 onClick={onClick} className={cls} dangerouslySetInnerHTML={dangerouslySetInnerHTML}>
          {children}
        </h4>
      );
    case "h5":
      return (
        <h5 onClick={onClick} className={cls} dangerouslySetInnerHTML={dangerouslySetInnerHTML}>
          {children}
        </h5>
      );
    case "h6":
      return (
        <h6 onClick={onClick} className={cls} dangerouslySetInnerHTML={dangerouslySetInnerHTML}>
          {children}
        </h6>
      );
    case "p":
    default:
      return (
        <p onClick={onClick} className={cls} dangerouslySetInnerHTML={dangerouslySetInnerHTML}>
          {children}
        </p>
      );
  }
};

export default Text;
