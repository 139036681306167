import { Children, FC, PropsWithChildren, isValidElement } from "react";
import { classNames } from "../utils/string";

export type SkeletonProps = {
  className?: string;
};

const Skeleton: FC<PropsWithChildren<SkeletonProps>> = ({ className, children }) => {
  const modifiedChildren = Children.map(children, (child) => {
    if (!isValidElement(child)) return child;
    return {
      ...child,
      props: { ...child.props, className: classNames(child.props.className, "!bg-grey-super-light") }
    };
  });
  return <div className={classNames(className, "animate-pulse")}>{modifiedChildren}</div>;
};

export default Skeleton;
