import { FC, TouchEvent, useEffect, useRef, useState } from "react";
import { HouseForSaleImage } from "./types";
import prevIcon from "@iconify-icons/ph/caret-left-bold";
import nextIcon from "@iconify-icons/ph/caret-right-bold";
import crossIcon from "@iconify-icons/ph/x-bold";
import Overlay from "../../Layout/Overlay";
import { Icon } from "@iconify/react";
import HouseImageComponent from "./images/HouseImageComponent";
import { classNames } from "../../utils/string";
import Logo from "../../Widgets/Logo";
import useKeysUp from "../../hooks/useKeysUp";
import ContactMike from "./card/ContactMike";

export type HouseImagesOverlayProps = {
  houseName: string;
  houseRef: string;
  images: HouseForSaleImage[];
  selectedImage: number;
  setSelectedImage: (image: number | null) => void;
};

const HouseImagesOverlay: FC<HouseImagesOverlayProps> = ({
  houseRef,
  houseName,
  images,
  selectedImage,
  setSelectedImage
}) => {
  const selectedImageRef = useRef<HTMLImageElement>(null);
  useEffect(() => {
    selectedImageRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [selectedImage, selectedImageRef.current]);

  const selectPreviousImage = () => {
    const prevSelected = selectedImage - 1 < 0 ? images.length ?? 1 : selectedImage - 1;
    setSelectedImage(prevSelected);
  };

  const selectNextImage = () => {
    const nextSelected = selectedImage + 1 === images.length + 1 ? 0 : selectedImage + 1;
    setSelectedImage(nextSelected);
  };

  useKeysUp(["ArrowLeft", "ArrowRight"], (key) => {
    if (key === "ArrowLeft") {
      selectPreviousImage();
    }
    if (key === "ArrowRight") {
      selectNextImage();
    }
  });
  const [touchStart, setTouchStart] = useState<number | null>(null);
  const [touchEnd, setTouchEnd] = useState<number | null>(null);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;

  const onTouchStart = (e: TouchEvent) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e: TouchEvent) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) {
      selectNextImage();
    }
    if (isRightSwipe) {
      selectPreviousImage();
    }
  };
  return (
    <Overlay className="bg-grey-super-dark z-[999] p-2 lg:p-4 flex flex-col" onClick={() => setSelectedImage(null)}>
      <div
        className="relative select-none py-4 container mx-auto"
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        <Icon
          icon={prevIcon}
          className="rounded-full bg-white p-2 w-12 h-12 shadow-sm absolute top-1/2 left-2 opacity-75 hover:opacity-100 select-none z-30"
          onClick={(e) => {
            e.stopPropagation();
            selectPreviousImage();
          }}
        />
        <Logo className="absolute left-1/2 top-[calc(25vh+2.5rem)] -translate-x-1/2 z-10" />
        {selectedImage < images.length ? (
          <div
            className={classNames(
              "mx-auto relative z-20",
              "w-full h-[65vh]",
              "object-cover",
              "user-select-none select-none",
              "bg-transparent",
              "bg-center bg-no-repeat bg-cover"
            )}
            style={{ backgroundImage: `url("${images[selectedImage]?.src ?? "/visuals/logo.svg"})` }}
            onFocus={(e) => e.target.blur()}
            onClick={(e) => e.stopPropagation()}
          />
        ) : (
          <div
            className={classNames(
              "mx-auto relative z-20",
              "w-full h-[65vh] px-16",
              "flex flex-col items-center justify-center",
              "bg-white"
            )}
          >
            <ContactMike
              whatsappLink={`https://wa.me/+31623700433?text=Hi team FRESH, I would like to have some more info on a property on your website: http://fresh-properties.com/for-sale/${houseRef}. Kind regards, `}
              small
            />
          </div>
        )}
        <Icon
          icon={nextIcon}
          className="rounded-full bg-white p-2 w-12 h-12 shadow-sm absolute top-1/2 right-2 opacity-75 hover:opacity-100 select-none z-30"
          onClick={(e) => {
            e.stopPropagation();
            selectNextImage();
          }}
        />
        <Icon
          icon={crossIcon}
          className="rounded-full bg-white p-2 w-10 h-10 shadow absolute top-2 right-2 hover:bg-primary active:bg-primary hover:text-white active:text-white select-none z-30"
          onClick={() => setSelectedImage(null)}
        />
      </div>
      <div className="container mx-auto grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 flex-1 overflow-y-auto">
        {images.map((image, i) => (
          <HouseImageComponent
            key={`image-${i}`}
            ref={i === selectedImage ? selectedImageRef : undefined}
            {...image}
            houseName={houseName}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedImage(i);
            }}
            className={classNames(
              "!h-[200px]",
              i === selectedImage ? "opacity-100 border-4 border-white" : "opacity-60",
              "hover:opacity-100"
            )}
          />
        ))}
      </div>
    </Overlay>
  );
};

export default HouseImagesOverlay;
