export const isString = (subject: unknown): subject is string =>
  subject !== null && subject !== undefined && typeof subject === "string";

export const isNonEmptyString = (subject: unknown): subject is string => isString(subject) && subject.length > 0;

export const isEmptyString = (subject: unknown): subject is string => isString(subject) && subject.length === 0;

export const isStringArray = (subject: unknown): subject is Array<string> =>
  Array.isArray(subject) && subject.every(isString);

export const isObject = (arg: unknown): arg is Record<string, unknown> =>
  !!arg && typeof arg === "object" && !Array.isArray(arg);

export const isError = (subject: unknown): subject is Error =>
  isObject(subject) && subject.message !== undefined && subject.stack !== undefined;

export type Schema<T> = Partial<Record<keyof T, [string, boolean]>>;

export type BaseValidationResult<T> = {
  isValid: boolean;
  value?: T;
  errors?: string[];
};
export type PositiveValidationResult<T> = {
  isValid: true;
  value: T;
};
export type NegativeValidationResult = {
  isValid: false;
  errors: string[];
};
export type ValidationResult<T> = BaseValidationResult<T> & (PositiveValidationResult<T> | NegativeValidationResult);

export const validateInput = <T = unknown>(input: unknown, param: string, schema: Schema<T>): ValidationResult<T> => {
  if (!isObject(input)) {
    return {
      isValid: false,
      errors: [`Error: the supplied value is either undefined or not an object, expecting parameter "${param}".`]
    };
  }

  const errors = Object.keys(schema)
    .filter((key) => input[key] === undefined && schema[key as keyof T]![1])
    .map((key) => `Error: the supplied value is missing "${key}" of type: "${schema[key as keyof T]![0]}"`);
  // TODO: Better and nested error handling.
  return errors.length > 0
    ? { isValid: false, errors }
    : {
        isValid: true,
        value: Object.keys(schema)
          .filter((key) => input[key] !== undefined)
          .reduce(
            (previousValue: T, key: string) => ({
              ...previousValue,
              [key]: input[key]
            }),
            {} as T
          )
      };
};
