import { FC, useEffect, useRef, useState } from "react";
import NumberInput from "../../shared/Informer/components/Number/NumberInput";
import Text from "../../Widgets/Text/Text";
import { classNames } from "../../utils/string";
import { Icon } from "@iconify/react";
import crossIcon from "@iconify-icons/ph/x";
import caretDown from "@iconify-icons/ph/caret-down";
import caretUp from "@iconify-icons/ph/caret-up";
import { isNonEmptyString } from "../../utils/validation";
import { informerStyle } from "../../shared/Informer/constants";
import Button from "../../Widgets/Button/Button";
import { Language } from "../../Layout/Page";
import BooleanSwitch from "../../shared/Informer/components/Boolean/BooleanSwitch";
import { Accordion } from "flowbite-react";
import { Area } from "./types";

export type FilterBoxProps = {
  filters: string[];
  setFilters: (filters: string[]) => void;
  language: Language;
  pueblosAndAreas?: { pueblos: string[]; areas: Area[] };
};

const translations = {
  en: {
    filters: "Filters",
    area: "Area",
    selectArea: "Select an area",
    location: "Location",
    selectLocation: "Select a location",
    requirements: "Requirements",
    pool: "Pool",
    garden: "Garden",
    rooms: "Rooms",
    bedrooms: "Bedrooms",
    bathrooms: "Bathrooms",
    price: "Price",
    minimum: "Minimum",
    maximum: "Maximum",
    clearFilters: "Clear filters"
  },
  es: {
    filters: "Filtros",
    area: "Zona",
    selectArea: "Selecciona una zona",
    location: "Ubicación",
    selectLocation: "Selecciona una ubicación",
    requirements: "Requisitos",
    pool: "Piscina",
    garden: "Jardín",
    rooms: "Habitaciones",
    bedrooms: "Dormitorios",
    bathrooms: "Baños",
    price: "Precio",
    minimum: "Mínimo",
    maximum: "Máximo",
    clearFilters: "Borrar filtros"
  },
  nl: {
    filters: "Filters",
    area: "Gebied",
    selectArea: "Selecteer een gebied",
    location: "Locatie",
    selectLocation: "Selecteer een locatie",
    requirements: "Vereisten",
    pool: "Zwembad",
    garden: "Tuin",
    rooms: "Kamers",
    bedrooms: "Slaapkamers",
    bathrooms: "Badkamers",
    price: "Prijs",
    minimum: "Minimum",
    maximum: "Maximum",
    clearFilters: "Filters wissen"
  },
  de: {
    filters: "Filter",
    area: "Standort",
    selectArea: "Wählen Sie einen Standort",
    location: "Standort",
    selectLocation: "Wählen Sie einen Standort",
    requirements: "Anforderungen",
    pool: "Schwimmbad",
    garden: "Garten",
    rooms: "Zimmer",
    bedrooms: "Schlafzimmer",
    bathrooms: "Badezimmer",
    price: "Preis",
    minimum: "Minimum",
    maximum: "Maximum",
    clearFilters: "Filter löschen"
  }
};

type Translations = typeof translations;
type TranslationsKey = keyof Translations[Language];

const FilterBox: FC<FilterBoxProps> = ({ filters, setFilters, language, pueblosAndAreas }) => {
  const areas = pueblosAndAreas?.areas ?? [];
  areas.sort((a, b) => a.name.localeCompare(b.name));

  const text = (key: TranslationsKey) => translations[language][key];

  const minPrice = filters.find((filter) => filter.startsWith("minPrice="))?.split("=")?.[1];
  const setMinPrice = (value?: number) => {
    if (value === undefined) {
      setFilters(filters.filter((filter) => !filter.startsWith("minPrice=")));
    } else if (filters.find((filter) => filter.startsWith("minPrice="))) {
      setFilters(filters.map((filter) => (filter.startsWith("minPrice=") ? `minPrice=${value}` : filter)));
    } else {
      setFilters([...filters, `minPrice=${value}`]);
    }
  };
  const maxPrice = filters.find((filter) => filter.startsWith("maxPrice="))?.split("=")?.[1];
  const setMaxPrice = (value?: number) => {
    if (value === undefined) {
      setFilters(filters.filter((filter) => !filter.startsWith("maxPrice=")));
    } else if (filters.find((filter) => filter.startsWith("maxPrice="))) {
      setFilters(filters.map((filter) => (filter.startsWith("maxPrice=") ? `maxPrice=${value}` : filter)));
    } else {
      setFilters([...filters, `maxPrice=${value}`]);
    }
  };

  const pool = filters.find((filter) => filter.startsWith("pool="))?.split("=")?.[1] === "true";
  const setPool = (value: boolean) => {
    if (value) {
      setFilters(filters.filter((filter) => !filter.startsWith("pool=")).concat("pool=true"));
    } else {
      setFilters(filters.filter((filter) => !filter.startsWith("pool=")));
    }
  };

  const garden = filters.find((filter) => filter.startsWith("garden="))?.split("=")?.[1] === "true";
  const setGarden = (value: boolean) => {
    if (value) {
      setFilters(filters.filter((filter) => !filter.startsWith("garden=")).concat("garden=true"));
    } else {
      setFilters(filters.filter((filter) => !filter.startsWith("garden=")));
    }
  };

  const areaValueRef = useRef<HTMLDivElement | null>(null);
  const [areaDropDownOpen, setAreaDropDownOpen] = useState(false);
  const [areaDropDownOffset, setAreaDropDownOffset] = useState(35);
  const area = filters.find((filter) => filter.startsWith("area="))?.split("=")?.[1];
  const resolvedArea = areas.find((a) => a.name === area);

  useEffect(() => {
    setAreaDropDownOffset(Math.max(areaValueRef.current?.offsetHeight ?? 0, 35));
  }, [areaValueRef.current, area]);

  const pueblos = resolvedArea?.pueblos ?? pueblosAndAreas?.pueblos ?? [];
  pueblos.sort((a, b) => a.localeCompare(b));

  const locationValueRef = useRef<HTMLDivElement | null>(null);
  const [locationDropDownOpen, setLocationDropDownOpen] = useState(false);
  const [locationDropDownOffset, setLocationDropDownOffset] = useState(35);
  const locations =
    filters
      .find((filter) => filter.startsWith("locations="))
      ?.split("=")?.[1]
      ?.split(",")
      ?.filter((f) => isNonEmptyString(f)) ?? [];

  const setLocations = (value: string[]) => {
    setFilters(filters.filter((f) => !f.startsWith("locations=")).concat(`locations=${value.join(",")}`));
  };

  const addLocation = (location: string) => {
    setLocations([...locations, location]);
  };

  const removeLocation = (location: string) => {
    setLocations(locations.filter((l) => l !== location));
  };

  const setArea = (value: string) => {
    setFilters(filters.filter((f) => !f.startsWith("area=")).concat(`area=${value}`));
  };
  const removeArea = () => {
    setFilters(filters.filter((f) => !f.startsWith("area=")));
  };

  useEffect(() => {
    if (resolvedArea) {
      console.log(resolvedArea);
    }
  }, [resolvedArea]);

  useEffect(() => {
    setLocationDropDownOffset(Math.max(locationValueRef.current?.offsetHeight ?? 0, 35));
  }, [locationValueRef.current, locations]);

  const baths = filters.find((filter) => filter.startsWith("baths="))?.split("=")?.[1];
  const setBaths = (value?: number) => {
    if (value === undefined) {
      setFilters(filters.filter((filter) => !filter.startsWith("baths=")));
    } else if (filters.find((filter) => filter.startsWith("baths="))) {
      setFilters(filters.map((filter) => (filter.startsWith("baths=") ? `baths=${value}` : filter)));
    } else {
      setFilters([...filters, `baths=${value}`]);
    }
  };
  const beds = filters.find((filter) => filter.startsWith("beds="))?.split("=")?.[1];
  const setBeds = (value?: number) => {
    if (value === undefined) {
      setFilters(filters.filter((filter) => !filter.startsWith("beds=")));
    } else if (filters.find((filter) => filter.startsWith("beds="))) {
      setFilters(filters.map((filter) => (filter.startsWith("beds=") ? `beds=${value}` : filter)));
    } else {
      setFilters([...filters, `beds=${value}`]);
    }
  };

  return (
    <div
      className="w-full p-2 col-span-1 md:col-span-2"
      onClick={() => {
        setLocationDropDownOpen(false);
      }}
    >
      <Accordion>
        <Accordion.Panel>
          <Accordion.Title className="px-4">{text("filters")}</Accordion.Title>
          <Accordion.Content className="px-4">
            <div className="container mx-auto grid grid-cols-1 lg:grid-cols-2 p-2 lg:gap-x-8 gap-y-8">
              <div className="flex flex-col">
                <Text type="h4" className="!my-1">
                  {text("area")}
                </Text>
                <div className="relative">
                  <div
                    className={informerStyle.textInput(
                      "mt-0.5 flex flex-row !items-center !justify-stretch min-h-[33.6px]"
                    )}
                    ref={areaValueRef}
                    onClick={(e) => {
                      e.stopPropagation();
                      setAreaDropDownOpen(!areaDropDownOpen);
                    }}
                  >
                    <div className="w-full self-start flex flex-row items-start flex-wrap gap-1">
                      {area && (
                        <div
                          key={`area-${area}`}
                          className="cursor-default flex flex-row items-center gap-1 p-1 border rounded"
                        >
                          {area}
                          <div className="w-px h-5 ml-1 bg-grey-medium" />
                          <Icon
                            icon={crossIcon}
                            className="w-5 h-5 cursor-pointer divide-x"
                            onClick={(e) => {
                              e.stopPropagation();
                              removeArea();
                            }}
                          />
                        </div>
                      )}
                      {area === undefined && (
                        <Text className="mt-0.5 mb-0 self-center text-grey-regular">{text("selectArea")}</Text>
                      )}
                    </div>
                    <div className="w-px h-[2rem] mr-2 bg-grey-medium" />
                    <Icon icon={areaDropDownOpen ? caretUp : caretDown} className="w-6 h-6 cursor-pointer" />
                  </div>
                  {areaDropDownOpen && (
                    <ul
                      className={classNames(
                        "absolute z-10 w-full h-128 max-h-[25vh] overflow-y-auto",
                        "[&>:nth-child(odd)]:bg-grey-super-light",
                        "[&>:nth-child(even)]:bg-white"
                      )}
                      style={{ top: locationDropDownOffset }}
                    >
                      {areas
                        .filter((a) => area !== a.name)
                        .map((area) => (
                          <li
                            key={area.name}
                            className="px-2 py-4 lg:py-2 cursor-pointer hover:!bg-primary hover:text-white active:!bg-primary active:text-white"
                            onClick={(e) => {
                              e.stopPropagation();
                              setArea(area.name);
                              setAreaDropDownOpen(false);
                            }}
                          >
                            {area.name}
                          </li>
                        ))}
                    </ul>
                  )}
                </div>
              </div>
              <div className="flex flex-col">
                <Text type="h4" className="!my-1">
                  {text("location")}
                </Text>
                <div className="relative">
                  <div
                    className={informerStyle.textInput(
                      "mt-0.5 flex flex-row !items-center !justify-stretch min-h-[33.6px]"
                    )}
                    ref={locationValueRef}
                    onClick={(e) => {
                      e.stopPropagation();
                      setLocationDropDownOpen(!locationDropDownOpen);
                    }}
                  >
                    <div className="w-full self-start flex flex-row items-start flex-wrap gap-1">
                      {locations.map((location) => (
                        <div
                          key={`loc-${location}`}
                          className="cursor-default flex flex-row items-center gap-1 p-1 border rounded"
                        >
                          {location}
                          <div className="w-px h-5 ml-1 bg-grey-medium" />
                          <Icon
                            icon={crossIcon}
                            className="w-5 h-5 cursor-pointer divide-x"
                            onClick={(e) => {
                              e.stopPropagation();
                              removeLocation(location);
                            }}
                          />
                        </div>
                      ))}
                      {locations.length === 0 && (
                        <Text className="mt-0.5 mb-0 self-center text-grey-regular">{text("selectLocation")}</Text>
                      )}
                    </div>
                    <div className="w-px h-[2rem] mr-2 bg-grey-medium" />
                    <Icon icon={locationDropDownOpen ? caretUp : caretDown} className="w-6 h-6 cursor-pointer" />
                  </div>
                  {locationDropDownOpen && (
                    <ul
                      className={classNames(
                        "absolute z-10 w-full h-128 max-h-[25vh] overflow-y-auto",
                        "[&>:nth-child(odd)]:bg-grey-super-light",
                        "[&>:nth-child(even)]:bg-white"
                      )}
                      style={{ top: locationDropDownOffset }}
                    >
                      {pueblos
                        .filter((pueblo) => !locations.includes(pueblo))
                        .map((pueblo) => (
                          <li
                            key={pueblo}
                            className="px-2 py-4 lg:py-2 cursor-pointer hover:!bg-primary hover:text-white active:!bg-primary active:text-white"
                            onClick={(e) => {
                              e.stopPropagation();
                              addLocation(pueblo);
                              setLocationDropDownOpen(false);
                            }}
                          >
                            {pueblo}
                          </li>
                        ))}
                    </ul>
                  )}
                </div>
              </div>
              <div>
                <Text type="h4" className="!my-1">
                  {text("requirements")}
                </Text>
                <div className="flex flex-row items-center gap-2 my-3">
                  <Text className="flex-1 !my-2 mr-2 min-w-[110px]">{text("pool")}</Text>
                  <BooleanSwitch value={pool} onChange={setPool} />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <Text className="flex-1 !my-2 mr-2 min-w-[110px]">{text("garden")}</Text>
                  <BooleanSwitch value={garden} onChange={setGarden} />
                </div>
              </div>
              <div className="flex flex-col">
                <Text type="h4" className="!my-1">
                  {text("rooms")}
                </Text>
                <div className="flex flex-row items-center gap-2">
                  <Text className="flex-1 mr-2 min-w-[110px]">{text("bedrooms")}</Text>
                  <NumberInput
                    placeholder={text("bedrooms")}
                    step={1}
                    value={beds ? parseInt(beds) : undefined}
                    onChange={setBeds}
                  />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <Text className="flex-1 mr-2 min-w-[110px]">{text("bathrooms")}</Text>
                  <NumberInput
                    placeholder={text("bathrooms")}
                    step={1}
                    value={baths ? parseInt(baths) : undefined}
                    onChange={setBaths}
                  />
                </div>
              </div>
            </div>
            <div className="container mx-auto">
              <div className="flex flex-col">
                <Text type="h4" className="!my-1">
                  {text("price")}
                </Text>
                <div className="flex flex-row items-center gap-2">
                  <Text className="flex-1 mr-2 min-w-[110px]">{text("minimum")}</Text>
                  <NumberInput
                    placeholder="(€)"
                    step={10000}
                    value={minPrice ? parseInt(minPrice) : undefined}
                    onChange={setMinPrice}
                  />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <Text className="flex-1 mr-2 min-w-[110px]">{text("maximum")}</Text>
                  <NumberInput
                    placeholder="(€)"
                    step={10000}
                    value={maxPrice ? parseInt(maxPrice) : undefined}
                    onChange={setMaxPrice}
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <Button onClick={() => setFilters([])}>{text("clearFilters")}</Button>
              </div>
            </div>
          </Accordion.Content>
        </Accordion.Panel>
      </Accordion>
    </div>
  );
};

export default FilterBox;
