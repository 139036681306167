export const constrainValue = (
  value: number | undefined,
  min: number | undefined,
  max: number | undefined
): number | undefined => {
  if (value === undefined) {
    return undefined;
  }
  let constrainedValue = value;
  if (min !== undefined) {
    constrainedValue = Math.max(constrainedValue, min);
  }
  if (max !== undefined) {
    constrainedValue = Math.min(constrainedValue, max);
  }
  return constrainedValue;
};
