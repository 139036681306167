import { FC } from "react";

export type ImageSettings = {
  src: string;
  alt?: string;
};
export type ImageComponentProps = ImageSettings & {
  className?: string;
  width?: string;
  height?: string;
};

const ImageComponent: FC<ImageComponentProps> = ({ src, alt, width, height, className }) => {
  return (
    <img className={className} width={width} height={height} src={src} alt={alt ?? "Photograph of the property"} />
  );
};

export default ImageComponent;
