import { FC, PropsWithChildren } from "react";
import { classNames } from "../../utils/string";
import { getClassName } from "./helpers";
import { BaseButtonProps } from "./types";

export type ButtonProps = PropsWithChildren<
  BaseButtonProps & {
    onClick?: () => void;
    className?: string;
    disabled?: boolean;
  }
>;

const Button: FC<ButtonProps> = ({ type = "primary", className, onClick, disabled, children }) => {
  return (
    <button className={classNames(getClassName(type), className)} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
};

export default Button;
