import { FC, PropsWithChildren } from "react";
import HorizontalDivider from "../../Dividers/HorizontalDivider";
import Text from "../../Text/Text";

export type CardFooterProps = PropsWithChildren<{}>;

const CardFooter: FC<CardFooterProps> = ({ children }) => {
  const resolvedChildren = typeof children === "string" ? <Text className="px-4 pb-2">{children}</Text> : children;
  return (
    <>
      <HorizontalDivider />
      {resolvedChildren}
    </>
  );
};

export default CardFooter;
