import { ClassnameArg, classNames } from "../../utils/string";

export const informerStyle = {
  textInput: (...args: ClassnameArg[]) =>
    classNames(
      "w-full",
      "px-2 py-1",
      "border border-grey-regular rounded",
      "focus:outline-none focus:ring-2 focus:ring-primary focus:border-primary",
      ...args
    )
};
