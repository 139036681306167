import { Suspense, lazy, useEffect } from "react";
import axios from "axios";
import { Routes, Route, useLocation } from "react-router-dom";
import { transformCase } from "./utils/string";
import ErrorBoundary from "./Widgets/ErrorBoundary";
import Layout from "./Layout/Layout";
import ScrollToTop from "./Layout/ScrollToTop";
import Text from "./Widgets/Text/Text";
import Page, { AllLanguages } from "./Layout/Page";
import { allPueblos } from "./Pages/PuebloSite/constants";
import Loader from "./Widgets/Feedback/Progress/Loader/Loader";
import { process } from "./process";
const LongTermDetail = lazy(() => import("./Pages/LongTerm/LongTermDetail"));
const LongTerm = lazy(() => import("./Pages/LongTerm/LongTerm"));
const PuebloSite = lazy(() => import("./Pages/PuebloSite/PuebloSite"));
const Contact = lazy(() => import("./Pages/Contact/Contact"));
const FAQ = lazy(() => import("./Pages/FAQ/FAQ"));
const ThingsToDoPage = lazy(() => import("./Pages/ThingsToDo/ThingsToDoPage"));
const Home = lazy(() => import("./Pages/Home/Home"));
const MeetTheTeam = lazy(() => import("./Pages/MeetTheTeam/MeetTheTeam"));
const HolidayRentalManagement = lazy(() => import("./Pages/HolidayRentalManagement/HolidayRentalManagement"));
const PropertyManagement = lazy(() => import("./Pages/PropertyManagement/PropertyManagement"));
const InvestmentAdvice = lazy(() => import("./Pages/InvestmentAdvice/InvestmentAdvice"));
const Services = lazy(() => import("./Pages/Services/Services"));
const BuyingAgent = lazy(() => import("./Pages/BuyingAgent/BuyingAgent"));
const NewForSale = lazy(() => import("./Pages/ForSale/NewForSale"));
const NewForSaleDetail = lazy(() => import("./Pages/ForSale/NewForSaleDetail"));
const AllBlogs = lazy(() => import("./Pages/Blog/AllBlogs"));
const NewDevelopment = lazy(() => import("./Pages/NewDevelopments/NewDevelopments"));
const DetailDevelopment = lazy(() => import("./Pages/NewDevelopments/DetailDevelopments"));
const App = () => {
  const { pathname, search } = useLocation();
  useEffect(() => {
    if (process.env.REACT_ENV === "production") {
      axios.post("/visit", { url: pathname, search });
    }
  }, [pathname]);

  //const logSiteMap = async () => {
  //  const sitemap = await generateSiteMap();
  //  console.log(sitemap);
  //};
  //logSiteMap();
  return (
    <ErrorBoundary>
      <Layout>
        <ScrollToTop>
          <Suspense fallback={<Loader type="abs" />}>
            <Routes>
              <Route
                element={
                  <Page>
                    <Contact />
                  </Page>
                }
                path="/contact/:language?"
              />
              <Route
                element={
                  <Page>
                    <FAQ />
                  </Page>
                }
                path="/faq/:language?"
              />
              <Route
                element={
                  <Page>
                    <NewDevelopment />
                  </Page>
                }
                path="/new-developments/:language?"
              />{" "}
              <Route
                element={
                  <Page>
                    <DetailDevelopment />
                  </Page>
                }
                path="/detail-developments/:id" // Adding the ":id" parameter to the path
              />
              <Route element={<ThingsToDoPage />} path="/axarquia/:language?" />
              <Route
                element={
                  <Page>
                    <MeetTheTeam />
                  </Page>
                }
                path="/meet-your-team/:language?"
              />
              <Route element={<HolidayRentalManagement />} path="/services/holiday-rental-management/:language?" />
              <Route element={<PropertyManagement />} path="/services/property-management/:language?" />
              <Route element={<InvestmentAdvice />} path="/services/property-investment/:language?" />
              <Route element={<BuyingAgent />} path="/services/Purchase-Agent/:language?" />
              <Route element={<Services />} path="/services/:language?" />
              {/*<Route
                key={`long-term`}
                element={
                  <Page>
                    <LongTerm />
                  </Page>
                }
                path={`/long-term`}
              />
              {AllLanguages.map((language) => (
                <Route
                  key={`long-term-${language}`}
                  element={
                    <Page>
                      <LongTerm />
                    </Page>
                  }
                  path={`/long-term/${language}`}
                />
              ))}
              <Route
                element={
                  <Page>
                    <LongTermDetail />
                  </Page>
                }
                path="/long-term/:ref/:language?"
              />*/}
              {AllLanguages.map((language) => (
                <Route
                  key={`for-sale-${language}`}
                  element={
                    <Page>
                      <NewForSale />
                    </Page>
                  }
                  path={`/for-sale/${language}`}
                />
              ))}
              <Route
                element={
                  <Page>
                    <NewForSaleDetail />
                  </Page>
                }
                path="/for-sale/:ref/:language?"
              />
              <Route
                element={
                  <Page>
                    <NewForSale />
                  </Page>
                }
                path="/for-sale/:language?"
              />
              {allPueblos.map((pueblo) => (
                <Route
                  key={pueblo}
                  element={
                    <Page>
                      <PuebloSite pueblo={pueblo} />
                    </Page>
                  }
                  path={`/${transformCase(pueblo, "kebab")}/:language?`}
                />
              ))}
              <Route element={<Home />} path="/:language?" />
              <Route
                element={
                  <Page>
                    <AllBlogs />
                  </Page>
                }
                path="/blogs/:name?/:language?"
              />
              <Route
                element={
                  <Page>
                    <Text type="h2" className="mt-8">
                      Sorry, the page doesn't exist
                    </Text>
                  </Page>
                }
                path="*"
              />
            </Routes>
          </Suspense>
        </ScrollToTop>
      </Layout>
    </ErrorBoundary>
  );
};

export default App;
