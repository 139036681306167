import { classNames } from "../../utils/string";
import { ButtonType } from "./types";

export const getClassName = (type: ButtonType) => {
  switch (type) {
    case "inverse":
      return classNames(
        "px-4",
        "py-2",
        "rounded",
        "bg-white",
        "border-white",
        "border",
        "text-primary",
        "fill-primary",
        "enabled:hover:text-secondary",
        "enabled:hover:fill-secondary",
        "enabled:focus:bg-primary",
        "enabled:focus:text-white",
        "enabled:focus:fill-white",
        "enabled:active:bg-primary",
        "enabled:active:text-white",
        "enabled:active:fill-white",
        "disabled:bg-grey-super-light",
        "disabled:bg-text-regular",
        "outline-none",
        "text-lg",
        "hover:scale-105",
        "active:scale-95",
        "transition-transform"
      );
    case "primary":
    default:
      return classNames(
        "px-4",
        "py-2",
        "rounded",
        "bg-primary",
        "text-white",
        "fill-white",
        "text-lg",
        "disabled:bg-grey-light",
        "enabled:hover:scale-105",
        "enabled:active:scale-95",
        "transition-transform"
      );
  }
};
