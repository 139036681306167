import { FC, PropsWithChildren, useRef } from "react";
import { createPortal } from "react-dom";
import { classNames } from "../../../utils/string";
import { motion } from "framer-motion";
import useOnClickOnTarget from "../../../hooks/useOnClickOnTarget";

export type OverlayProps = PropsWithChildren<{
  className?: string;
  onClose?: () => void;
}>;

const Overlay: FC<OverlayProps> = ({ className, onClose, children }) => {
  const ref = useRef<HTMLDivElement>(null);
  const onClick = onClose ? useOnClickOnTarget(ref, onClose) : undefined;
  return createPortal(
    <motion.div
      ref={ref}
      className={classNames("fixed z-30 top-0 left-0 w-full h-full bg-grey-super-dark/50", className)}
      onClick={onClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {children}
    </motion.div>,
    document.body
  );
};

export default Overlay;
