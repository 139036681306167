import { FC } from "react";
import ImageComponent from "../Layout/ImageComponent";
import { classNames } from "../utils/string";

export type LogoProps = {
  className?: string;
};

const Logo: FC<LogoProps> = ({ className }) => {
  return (
    <div
      className={classNames(
        "w-40 h-40 flex items-center justify-center bg-white border-primary border-4 top-20 rounded-full shadow-sm",
        className
      )}
    >
      <ImageComponent className="w-36 h-36" src="/logo.svg" alt="FRESH logo" />
    </div>
  );
};

export default Logo;
