import { FC } from "react";
import ImageComponent from "../../Layout/ImageComponent";
import { stripSpecialCaracters, transformCase } from "../../utils/string";
import Text from "../../Widgets/Text/Text";

export type TeamCardProps = {
  name: string;
  role: string;
  isFounder?: boolean;
};

const TeamCard: FC<TeamCardProps> = ({ name, role, isFounder }) => {
  return (
    <div className="p-4 h-full">
      <div className="h-full bg-white text-black rounded-md shadow-lg overflow-hidden">
        <ImageComponent
          className="w-full h-[250px] object-cover"
          height="250px"
          src={`/team/${transformCase(stripSpecialCaracters(name.toLowerCase()), "snakeLowerFirst")}.webp`}
        />
        <div className="p-4">
          <Text type="h4">{name}</Text>
          {isFounder && <Text>Founder</Text>}
          <Text>{role}</Text>
        </div>
      </div>
    </div>
  );
};

export default TeamCard;
