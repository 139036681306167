export type StringOfLength<N extends number> = {
  0: string;
  length: N;
} & string;
type Lengthy = { length: number };
type Length<S extends Lengthy> = S extends { length: infer X } ? X : never;
export type isStringOfLength<S extends Lengthy, N extends number> = Length<S> extends N ? true : false;

export type ClassnameArg = string | string[] | undefined | null | false;

export const classNames = (...args: ClassnameArg[]): string => {
  const notNullUndefinedOrFalse = args.filter((v) => !!v).flat() as string[];
  return notNullUndefinedOrFalse
    .flatMap((v) => v.split(" "))
    .filter((v) => v.length > 0)
    .join(" ")
    .trim();
};

export type FirstLetterCasing = "upper" | "none";

export const separators = [" ", "-", "_"];
export const onlyAlphaNumeric = (input: string): string =>
  input
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/([^a-z,0-9\-_ \,])/gi, "");

export const splitToWords = (input: string): string[] =>
  onlyAlphaNumeric(input)
    .split(/( |\-|_|(?=[A-Z]))/g)
    .filter((s) => !separators.includes(s));

export const lowerCaseFirstLetter = (input: string): string => input.slice(0, 1).toLowerCase().concat(input.slice(1));

export const upperCaseFirstLetter = (input: string): string => input.slice(0, 1).toUpperCase().concat(input.slice(1));

export const resolveFirstLetterCasing = (input: string, casing: FirstLetterCasing) =>
  casing === "upper" ? upperCaseFirstLetter(input) : input;

export type StringCase =
  | "camel"
  | "kebab"
  | "lower"
  | "lowerFirst"
  | "pascal"
  | "snakeLowerFirst"
  | "snakeUpperFirst"
  | "upper"
  | "upperFirst"
  | "upperSentence";

export const stripSpecialCaracters = (input: string) => {
  return input
    .replaceAll(/(á|ä|â|à|ã|å|ā)/g, "a")
    .replaceAll(/(é|ë|ê|è|ę|ė|ē)/g, "e")
    .replaceAll(/(ú|ü|û|ù|ū)/g, "u")
    .replaceAll(/(ó|ö|ô|ò|ø|ō)/g, "o")
    .replaceAll(/(í|ï|ì|î\į|ī)/g, "i")
    .replaceAll(/(ñ|ń)/g, "n");
};

export const transformCase = (input: string, target: StringCase): string => {
  const cleaned = onlyAlphaNumeric(input);
  switch (target) {
    case "camel":
      return lowerCaseFirstLetter(splitToWords(cleaned.toLowerCase()).map(upperCaseFirstLetter).join(""));
    case "kebab":
      return splitToWords(cleaned.toLowerCase()).join("-");
    case "lower":
      return input.toLowerCase();
    case "lowerFirst":
      return lowerCaseFirstLetter(input);
    case "pascal":
      return splitToWords(cleaned.toLowerCase()).map(upperCaseFirstLetter).join("");
    case "snakeLowerFirst":
      return splitToWords(cleaned.toLowerCase()).join("_");
    case "snakeUpperFirst":
      return upperCaseFirstLetter(splitToWords(cleaned.toLowerCase()).join("_"));
    case "upper":
      return input.toUpperCase();
    case "upperFirst":
      return upperCaseFirstLetter(input);
    case "upperSentence":
      return splitToWords(cleaned).map(upperCaseFirstLetter).join(" ");
  }
};
