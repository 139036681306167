import { FC, useRef } from "react";
import Page from "../../Layout/Page";
import PropertyCallToAction from "./PropertyCallToAction";
import PropertyTransparentPricing from "./PropertyTransparentPricing";
import SEO from "../../Widgets/SEO/SEO";

export type PropertyManagementProps = {};

const seo = {
  title: {
    en: "Minimize your Hassle with FRESH Property Management",
    es: "Minimice su molestia con la administración de propiedades FRESH",
    nl: "Minimaliseer uw gedoe met FRESH Property Management",
    de: "Minimieren Sie Ihren Aufwand mit FRESH Property Management"
  },
  description: {
    en: "Experience the peace of mind that comes with entrusting your property to experienced professionals. Our comprehensive Property Management services handle everything, so you can sit back and relax.",
    es: "Experimente la tranquilidad que brinda confiar su propiedad a profesionales experimentados. Nuestros completos servicios de administración de propiedades se encargan de todo, para que pueda sentarse y relajarse.",
    nl: "Ervaar de gemoedsrust die gepaard gaat met het toevertrouwen van uw woning aan ervaren professionals. Onze uitgebreide Property Management-services regelen alles, zodat u achterover kunt leunen en ontspannen.",
    de: "Erleben Sie die Sicherheit, die mit dem Vertrauen Ihrer Immobilie an erfahrene Fachleute einhergeht. Unser umfassender Property Management-Service erledigt alles, damit Sie sich zurücklehnen und entspannen können."
  }
};

const PropertyManagement: FC<PropertyManagementProps> = ({}) => {
  const rentalAdviceRef = useRef<HTMLDivElement>(null);
  return (
    <Page>
      <SEO title={seo.title} description={seo.description}>
        <PropertyCallToAction rentalAdviceRef={rentalAdviceRef} />
        <PropertyTransparentPricing />
      </SEO>
    </Page>
  );
};

export default PropertyManagement;
