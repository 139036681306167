import { FC, PropsWithChildren, useEffect, useRef, useState } from "react";
import { classNames } from "./utils/string";
import waIcon from "@iconify-icons/ph/whatsapp-logo-fill";
import mailIcon from "@iconify-icons/ph/envelope";
import { Icon } from "@iconify/react";

export type WhatsAppLinkProps = {
  color?: "primary" | "secondary" | "white";
  subject: string;
  message: string;
};
const liClassName =
  "group px-6 py-6 lg:py-4 flex flex-row items-center cursor-pointer hover:!bg-primary hover:text-white active:!bg-secondary active:text-white";
const icClassName = "w-8 h-8 mr-4 group-hover:text-white group-active:text-white";

const sendWhatsApp = (message: string) => {
  window.open(`https://api.whatsapp.com/send/?phone=%2B31623700433&text=${encodeURIComponent(message)}`, "_blank");
};
const sendEmail = (subject: string, message: string) => {
  window.open(
    `mailto:info@fresh-propertymanagement.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
      message
    )}`
  );
};
const WhatsAppLink: FC<PropsWithChildren<WhatsAppLinkProps>> = ({ subject, message, color = "primary", children }) => {
  const ref = useRef<HTMLSpanElement>(null);
  const [position, setPosition] = useState({ y: 0 });
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const onDocumentClick = () => {
      setOpen(false);
    };
    document.addEventListener("click", onDocumentClick);
    return () => document.removeEventListener("click", onDocumentClick);
  }, []);

  useEffect(() => {
    const rect = ref.current?.getBoundingClientRect();
    if (rect) {
      setPosition({ y: rect.height + 5 });
    }
  }, [ref.current]);

  return (
    <span className="relative z-30">
      <span
        ref={ref}
        className={classNames(
          color === "primary" && "text-primary",
          color === "secondary" && "text-secondary",
          color === "white" && "text-white",
          "underline cursor-pointer"
        )}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!open);
        }}
      >
        {children}
      </span>
      {open && (
        <span
          className="absolute border border-grey-ultra-light [&>:nth-child(odd)]:bg-white [&>:nth-child(even)]:bg-grey-super-light text-black rounded overflow-clip shadow-lg left-1/2 -translate-x-1/2 z-40"
          style={{ bottom: position.y }}
        >
          <span className={liClassName} onClick={() => sendWhatsApp(message)}>
            <Icon icon={waIcon} className={classNames(icClassName, "text-primary")} />
            Whatsapp
          </span>
          <span className={liClassName} onClick={() => sendEmail(subject, message)}>
            <Icon icon={mailIcon} className={classNames(icClassName, "text-blue")} />
            E-mail
          </span>
        </span>
      )}
    </span>
  );
};

export default WhatsAppLink;
