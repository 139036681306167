import { FC, KeyboardEvent, PropsWithChildren, useCallback } from "react";
import useFocus from "../../hooks/useFocus";
import { classNames } from "../../utils/string";

export type TabProps = {
  index: number;
  active: boolean;
  onClick: () => void;
};

const Tab: FC<PropsWithChildren<TabProps>> = ({ children, index, active, onClick }) => {
  const [hasFocus, focusProps] = useFocus();
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (hasFocus && (e.key === " " || e.key === "Enter")) {
        onClick();
      }
    },
    [hasFocus]
  );

  return (
    <div
      {...focusProps}
      className={classNames(
        "bg-primary text-white px-2 py-4 flex flex-1 items-center justify-center cursor-pointer",
        "[&:not(:last-child)]:border-r-white",
        "[&:not(:last-child)]:border-r-solid",
        "[&:not(:last-child)]:border-r",
        "[&>*]:select-none",
        "[&>*]:m-0",
        active ? "opacity-100 animate-[clickScale_250ms_ease-in-out]" : "opacity-40"
      )}
      role="tab"
      aria-label={`tab ${index + 1}`}
      aria-selected={active}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Tab;
