import { FC, useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import caretLeft from "@iconify-icons/ph/caret-left";
import caretRight from "@iconify-icons/ph/caret-right";
import { PropertyLongTerm, extractLongTermImages } from "./types";
import Text from "../../Widgets/Text/Text";
import { process } from "../../process";
import ImageSlider from "../../Widgets/ImageSlider";
import { Link, useParams } from "react-router-dom";
import useGetData from "../../hooks/useGetData";
import Page, { Language } from "../../Layout/Page";
import Box from "../../Layout/Box";
import Container from "../../Layout/Container";
import Loader from "../../Widgets/Loader";
import SEO from "../../Widgets/SEO/SEO";
import { classNames } from "../../utils/string";

const translations = {
  en: {
    title: "Our long term rentals",
    noListings: "We are sorry, the property you are looking for is no longer available.",
    description:
      "We have a range of long term rental properties available in the Costa del Sol. Please contact us for more information.",
    contactUs:
      "Do you like this property? <a href='/contact' class='text-primary'>Contact us</a> and we will help you further."
  },
  es: {
    title: "Nuestros alquileres a largo plazo",
    noListings: "Lo sentimos, la propiedad que busca ya no está disponible.",
    description:
      "Tenemos una variedad de propiedades en alquiler a largo plazo disponibles en la Costa del Sol. Por favor contáctenos para más información.",
    contactUs:
      "¿Te gusta esta propiedad? <a href='/contact/es' class='text-primary'>Contáctanos</a> y te ayudaremos más."
  },
  nl: {
    title: "Onze lange termijn verhuur",
    noListings: "Het spijt ons, de woning die u zoekt is niet langer beschikbaar.",
    description:
      "We hebben een scala aan lange termijn verhuur beschikbaar in de Costa del Sol. Neem contact met ons op voor meer informatie.",
    contactUs:
      "Vindt je deze woning leuk? <a href='/contact/nl' class='text-primary'>Neem contact met ons op</a> en wij helpen je verder."
  },
  de: {
    title: "Unsere Langzeitmieten",
    noListings: "Es tut uns leid, die von Ihnen gesuchte Immobilie ist nicht mehr verfügbar.",
    description:
      "Wir haben eine Reihe von Langzeitmietobjekten an der Costa del Sol. Bitte kontaktieren Sie uns für weitere Informationen.",
    contactUs:
      "Gefällt Ihnen diese Immobilie? <a href='/contact/de' class='text-primary'>Kontaktieren Sie uns</a> und wir helfen Ihnen weiter."
  }
};

export type LongTermDetailProps = {};

const capIndex = (idx: number, arr: Array<unknown>) => {
  const length = arr.length;
  while (idx < 0) {
    idx += length;
  }
  while (idx >= length) {
    idx -= length;
  }
  return idx;
};

const LongTermDetail: FC<LongTermDetailProps> = () => {
  const { ref, language = "en" } = useParams<{ ref: string; language?: Language }>();
  const [loading, listings, reload] = useGetData<PropertyLongTerm[]>("/long-term");
  const [listing, setListing] = useState<PropertyLongTerm | undefined>(undefined);
  const [images, setImages] = useState<string[]>([]);
  const [previous, setPrevious] = useState<string>("/long-term");
  const [next, setNext] = useState<string>("/long-term");

  useEffect(() => {
    if (listings && listings.length > 0) {
      const idx = listings.findIndex((l) => l._id === ref);
      if (idx === -1) {
        return;
      }
      const prevIdx = capIndex(idx - 1, listings);
      const nextIdx = capIndex(idx + 1, listings);
      const listing = listings[idx];
      setListing(listing);
      setImages(extractLongTermImages(listing?.images));
      setPrevious(`/long-term/${listings[prevIdx]._id}`);
      setNext(`/long-term/${listings[nextIdx]._id}`);
    }
  }, [listings]);
  return (
    <SEO
      title={listing?.name ?? translations[language].title}
      description={listing?.description ?? translations[language].description}
    >
      <Page>
        <Box>
          <Container>
            <Text className="text-center block" type="h2">
              {translations[language].title}
            </Text>
            <Loader loading={loading}>
              {listing && (
                <div>
                  <div className="flex flex-row items-center justify-between w-96 mx-auto">
                    <Link
                      to={`${previous}${language && `/${language}`}`}
                      className={classNames("px-4", (listings?.length ?? 0) < 2 && "opacity-20 pointer-events-none")}
                      onClick={reload}
                    >
                      <Icon icon={caretLeft} className="w-8 h-8" />
                    </Link>
                    <div>
                      <Text type="h4">{listing.name}</Text>
                      <Text type="h5">{listing.city}</Text>
                    </div>
                    <Link
                      to={`${next}${language && `/${language}`}`}
                      className={classNames("px-4", (listings?.length ?? 0) < 2 && "opacity-20 pointer-events-none")}
                      onClick={reload}
                    >
                      <Icon icon={caretRight} className="w-8 h-8" />
                    </Link>
                  </div>
                  <div className="flex flex-row items-center justify-between">
                    {listing.sizeM2 && <Text>{listing.sizeM2}m2</Text>}
                    {listing.price && <Text type="h3">€{listing.price}</Text>}
                  </div>{" "}
                  {images.length > 0 && (
                    <ImageSlider
                      images={images.map((img, i) => ({
                        src: `${process.env.REACT_APP_API}${img}`,
                        alt: `Photo ${i} of ${listing.name}`
                      }))}
                    />
                  )}
                  <div
                    className="text-left text-lg mt-2"
                    dangerouslySetInnerHTML={{ __html: listing.description ?? "" }}
                  />
                  <Text className="mt-8 mb-4" dangerouslySetInnerHTML={{ __html: translations[language].contactUs }} />
                </div>
              )}
              {!listing && <Text className="text-center block">{translations[language].noListings}</Text>}
            </Loader>
          </Container>
        </Box>
      </Page>
    </SEO>
  );
};

export default LongTermDetail;
