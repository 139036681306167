import { forwardRef, MouseEvent } from "react";
import { classNames } from "../../../utils/string";
import { HouseForSaleImage } from "../types";

export type HouseImageComponentProps = HouseForSaleImage & {
  className?: string;
  houseName?: string;
  onClick?: (e: MouseEvent) => void;
};

const HouseImageComponent = forwardRef<HTMLImageElement, HouseImageComponentProps>(
  ({ src, alt, houseName, className, onClick }, ref) => {
    return (
      <img
        ref={ref}
        className={classNames("w-full h-[400px] object-cover", className)}
        src={src}
        alt={alt ?? houseName ?? "Image of the property"}
        onClick={onClick}
      />
    );
  }
);

export default HouseImageComponent;
