import { FC } from "react";
import { classNames } from "../../utils/string";

export type HorizontalRulerProps = {
  background?: string;
  className?: string;
};

const HorizontalRuler: FC<HorizontalRulerProps> = ({ background = "bg-grey-regular", className }) => (
  <hr className={classNames("h-px border-0", background, className)} />
);

export default HorizontalRuler;
