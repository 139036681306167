import axios from "axios";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import { process } from "./process";

axios.defaults.baseURL = process.env.REACT_APP_API;

document.documentElement.classList.remove("dark");
localStorage.theme = "light";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root?.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
