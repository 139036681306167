import { FC, PropsWithChildren, createContext, useEffect } from "react";
import { resolveStyle, StyleProps } from "../types";
import { classNames } from "../utils/string";
import { useParams } from "react-router-dom";
import { isNonEmptyString } from "../utils/validation";

export type PageProps = PropsWithChildren<StyleProps>;

export const AllLanguages = ["en", "es", "nl", "de"] as const;
export type Language = (typeof AllLanguages)[number];
export const isLanguage = (subject: unknown): subject is Language =>
  isNonEmptyString(subject) && AllLanguages.includes(subject as Language);

export type LanguageContextType = { language: Language };

export const LanguageContext = createContext<LanguageContextType>({
  language: window.location.hostname.endsWith("nl") ? "nl" : "en"
});

export type Translation = Record<Language, string>;

const Page: FC<PageProps> = ({ children, ...props }) => {
  const { language = "en" } = useParams<{ language: Language }>();

  useEffect(() => {
    document.documentElement.setAttribute("lang", language);
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language }}>
      <div className={classNames(resolveStyle(props))}>{children}</div>
    </LanguageContext.Provider>
  );
};

export default Page;
