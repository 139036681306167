import { FC, PropsWithChildren } from "react";
import { resolveStyle, StyleProps } from "../types";
import { classNames } from "../utils/string";

export type ContainerProps = PropsWithChildren<StyleProps & { extraTop?: boolean }>;

const Container: FC<ContainerProps> = ({ children, extraTop = false, ...props }) => {
  return (
    <section
      className={classNames(
        "container mx-auto px-2 lg:px-4 py-12",
        extraTop && "lg:pt-20",
        resolveStyle(props, { text: { align: "center" } })
      )}
    >
      {children}
    </section>
  );
};

export default Container;
