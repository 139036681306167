import axios from "axios";
import {
  FC,
  forwardRef,
  ForwardRefExoticComponent,
  PropsWithChildren,
  PropsWithoutRef,
  RefAttributes,
  useContext,
  useState
} from "react";
import { FieldValues, useForm, UseFormRegister } from "react-hook-form";
import Box from "../../Layout/Box";
import Container from "../../Layout/Container";
import Overlay from "../../Layout/Overlay";
import { classNames } from "../../utils/string";
import Button from "../../Widgets/Button/Button";
import Loader from "../../Widgets/Loader";
import HorizontalRuler from "../../Widgets/Separators/HorizontalRuler";
import Text from "../../Widgets/Text/Text";
import { LanguageContext } from "../../Layout/Page";

export type HomeFreeRentalAdviceProps = { color?: "primary" | "secondary" | "white" };
type FormState = "idle" | "sending" | "sent";

type FormPageProps = PropsWithChildren<{
  register: UseFormRegister<FieldValues>;
  enabled: boolean;
  text: (index: number) => string;
}>;

const translations = {
  en: [
    `Free rental advice`,
    `Interested in what your property could earn?`,
    `Please fill out the form and we will get back to you in no time!`,
    `Property location *`,
    `Number of bedrooms *`,
    `Calculate your income`,
    `Your name *`,
    `Email *`,
    `Phone number *`,
    `Previous`,
    `Calculate your income`,
    `Thank you!`,
    `We have received your request.`,
    `We will respond within 2 working days.`,
    `(Click to close this message)`
  ],
  es: [
    `Consejos de alquiler gratuitos`,
    `¿Interesado en lo que podría ganar su propiedad?`,
    `¡Complete el formulario y nos pondremos en contacto con usted en poco tiempo!`,
    `Ubicación de la propiedad *`,
    `Número de dormitorios *`,
    `Calcule sus ingresos`,
    `Tu nombre *`,
    `Correo electrónico *`,
    `Número de teléfono *`,
    `Anterior`,
    `Calcule sus ingresos`,
    `Gracias!`,
    `Hemos recibido su solicitud.`,
    `Responderemos dentro de los 2 días hábiles.`,
    `(Haga clic para cerrar este mensaje)`
  ],
  nl: [
    `Gratis verhuuradvies`,
    `Geïnteresseerd in wat uw woning zou kunnen opleveren?`,
    `Vul het formulier in en we nemen binnen no-time contact met u op!`,
    `Locatie van de woning *`,
    `Aantal slaapkamers *`,
    `Bereken uw inkomen`,
    `Uw naam *`,
    `E-mail *`,
    `Telefoonnummer *`,
    `Vorige`,
    `Bereken uw inkomen`,
    `Dank je!`,
    `We hebben uw verzoek ontvangen.`,
    `We reageren binnen 2 werkdagen.`,
    `(Klik om dit bericht te sluiten)`
  ],
  de: [
    `Kostenlose Mietberatung`,
    `Interessiert an dem, was Ihre Immobilie verdienen könnte?`,
    `Bitte füllen Sie das Formular aus und wir werden uns in kürzester Zeit bei Ihnen melden!`,
    `Standort der Immobilie *`,
    `Anzahl der Schlafzimmer *`,
    `Berechnen Sie Ihr Einkommen`,
    `Dein Name *`,
    `Email *`,
    `Telefonnummer *`,
    `Bisherige`,
    `Berechnen Sie Ihr Einkommen`,
    `Danke!`,
    `Wir haben Ihre Anfrage erhalten.`,
    `Wir antworten innerhalb von 2 Werktagen.`,
    `(Klicken Sie hier, um diese Nachricht zu schließen)`
  ]
};

const FormPage0: FC<FormPageProps> = ({ register, enabled, text, children }) =>
  enabled ? (
    <div className={classNames("mt-4 text-left text-black lg:grid lg:grid-rows-2 items-center")}>
      <Text>{text(3)}</Text>
      <input
        className="rounded border border-grey-regular px-4 py-2 my-2 w-full"
        type="text"
        {...register("location", { required: true })}
      />
      <Text>{text(4)}</Text>
      <input
        className="rounded border border-grey-regular px-4 py-2 my-2 w-full"
        type="number"
        {...register("bedrooms", { required: true })}
      />
      {children}
    </div>
  ) : null;

const FormPage1: FC<FormPageProps> = ({ register, enabled, text, children }) =>
  enabled ? (
    <div className={classNames("mt-4 text-left text-black lg:grid lg:grid-cols-2 items-center")}>
      <Text>{text(6)}</Text>
      <input
        className="rounded border border-grey-regular px-4 py-2 my-2 w-full"
        type="text"
        {...register("name", { required: true })}
      />
      <Text>{text(7)}</Text>
      <input
        className="rounded border border-grey-regular px-4 py-2 my-2 w-full"
        type="email"
        {...register("email", { required: true })}
      />
      <Text>{text(8)}</Text>
      <input
        className="rounded border border-grey-regular px-4 py-2 my-2 w-full"
        type="tel"
        {...register("phone", { required: true })}
      />
      {children}
    </div>
  ) : null;

const HomeFreeRentalAdvice: ForwardRefExoticComponent<
  PropsWithoutRef<HomeFreeRentalAdviceProps> & RefAttributes<HTMLDivElement>
> = forwardRef<HTMLDivElement, HomeFreeRentalAdviceProps>(({ color = "primary" }, ref) => {
  const { language } = useContext(LanguageContext);
  const text = (index: number) => translations[language][index] ?? "";
  const { register, watch } = useForm();
  const [page, setPage] = useState(0);
  const [formState, setFormState] = useState<FormState>("idle");
  const values = watch();

  return (
    <Box backgroundColor={color}>
      <Container>
        <div ref={ref} className="grid grid-cols-1 lg:grid-cols-2">
          <div className="text-left px-2">
            <Text className="text-white" type="h2">
              {text(0)}
            </Text>
            <Text className="text-white" type="h4" as="h3">
              {text(1)}
            </Text>
            <Text className="text-white">{text(2)}</Text>
          </div>
          <div className="bg-white rounded mx-auto px-8 py-4 w-full max-w-2xl">
            <FormPage0 enabled={page === 0} register={register} text={text}>
              <Button
                disabled={!values["location"]?.trim() || !values["bedrooms"]?.trim()}
                type="primary"
                className="col-span-2 my-4"
                onClick={() => setPage((page) => page + 1)}
              >
                {text(5)}
              </Button>
            </FormPage0>
            <Loader loading={formState === "sending"}>
              <FormPage1 enabled={page === 1} register={register} text={text}>
                <Button
                  type="primary"
                  className="col-span-1 my-4 flex-1 max-w-[calc(14rem+2px)] !bg-grey-dark"
                  onClick={() => setPage((page) => page - 1)}
                >
                  {text(9)}
                </Button>
                <Button
                  disabled={!values["name"]?.trim() || !values["email"]?.trim() || !values["phone"].trim()}
                  type="primary"
                  className="col-span-1 my-4 flex-1"
                  onClick={async () => {
                    setFormState("sending");
                    try {
                      await axios.post("/rental-advice", values);
                    } catch {}
                    setFormState("sent");
                  }}
                >
                  {text(10)}
                </Button>
              </FormPage1>
            </Loader>
          </div>
        </div>
      </Container>
      {formState === "sent" && (
        <Overlay
          className="cursor-pointer flex flex-col items-center justify-center bg-grey-super-dark/30"
          onClick={() => setFormState("idle")}
        >
          <div className="bg-white rounded drop-shadow-xl px-8 py-4">
            <Text type="h2">{text(11)}</Text>
            <HorizontalRuler />
            <Text>
              {text(12)}
              <br />
              {text(13)}
            </Text>
            <Text type="h6">{text(14)}</Text>
          </div>
        </Overlay>
      )}
      <svg
        className="w-[calc(100%+1.3px)] h-[76px] -scale-y-100 fill-white"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        preserveAspectRatio="none"
      >
        <path d="M0,0L80,48C160,96,320,192,480,202.7C640,213,800,139,960,144C1120,149,1280,235,1360,277.3L1440,320L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path>
      </svg>
    </Box>
  );
});

export default HomeFreeRentalAdvice;
