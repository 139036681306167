import { FC, PropsWithChildren } from "react";
import Text from "../../Text/Text";
import BackgroundWithRings from "../../Backgrounds/BackgroundWithRings";

export type CardHeaderProps = PropsWithChildren<{}>;

const CardHeader: FC<CardHeaderProps> = ({ children }) => {
  const resolvedChildren =
    typeof children === "string" ? (
      <Text type="h4" className="px-4">
        {children}
      </Text>
    ) : (
      children
    );

  return (
    <BackgroundWithRings>
      <div className="w-full flex flex-col items-center justify-center py-2">{resolvedChildren}</div>
    </BackgroundWithRings>
  );
};

export default CardHeader;
