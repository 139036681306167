import { FC, useContext } from "react";
import Box from "../../Layout/Box";
import Container from "../../Layout/Container";
import Text from "../../Widgets/Text/Text";
import { Icon } from "@iconify/react";
import checkMark from "@iconify-icons/ph/check-bold";
import { LanguageContext } from "../../Layout/Page";

export type BuyingAgentNextStepsProps = {};

const translations = {
  en: [
    "Our service does not stop here. If required, we can help you with;",
    "Requesting initial items such as energy suppliers, internet, telephone and insurance",
    "Any renovations through our local partners",
    "Furnishing your home, ask about our furniture packages",
    "Complete rental management, see also our website "
  ],
  es: [
    "Nuestro servicio no termina aquí. Si es necesario, podemos ayudarlo con;",
    "Solicitud de artículos iniciales como proveedores de energía, internet, teléfono y seguros",
    "Cualquier renovación a través de nuestros socios locales",
    "Amueblar su hogar, pregunte por nuestros paquetes de muebles",
    "Gestión completa de alquileres, consulte también nuestro sitio web "
  ],
  nl: [
    "Onze service stopt hier niet. Indien gewenst kunnen wij u helpen met;",
    "Aanvragen van eerste zaken zoals energieleveranciers, internet, telefoon en verzekeringen",
    "Eventuele verbouwingen via onze lokale partners",
    "Het inrichten van uw woning, vraag naar onze meubelpakketten",
    "Volledig verhuurbeheer, zie ook onze website "
  ],
  de: [
    "Unser Service endet hier nicht. Wenn gewünscht, können wir Ihnen helfen mit;",
    "Beantragung erster Artikel wie Energieversorger, Internet, Telefon und Versicherung",
    "Alle Renovierungen über unsere lokalen Partner",
    "Einrichten Ihres Hauses, fragen Sie nach unseren Möbelpaketen",
    "Vollständiges Mietmanagement, siehe auch unsere Website "
  ]
};

const BuyingAgentNextSteps: FC<BuyingAgentNextStepsProps> = ({}) => {
  const { language } = useContext(LanguageContext);
  let next = 0;
  const text = () => translations[language][next++] ?? "";
  return (
    <Box>
      <Container>
        <Text type="h4">{text()}</Text>
        <div className="p-4 lg:max-w-[800px] mx-auto">
          <div className="flex flex-row items-start justify-start text-left w-full">
            <Icon icon={checkMark} className="text-primary mt-4 w-12 h-6 mr-2" />
            <Text className="w-max flex-1">{text()}</Text>
          </div>
          <div className="flex flex-row items-start justify-start text-left w-full">
            <Icon icon={checkMark} className="text-primary mt-4 w-12 h-6 mr-2" />
            <Text className="w-max flex-1">{text()}</Text>
          </div>
          <div className="flex flex-row items-start justify-start text-left w-full">
            <Icon icon={checkMark} className="text-primary mt-4 w-12 h-6 mr-2" />
            <Text className="w-max flex-1">{text()}</Text>
          </div>
          <div className="flex flex-row items-start justify-start text-left w-full">
            <Icon icon={checkMark} className="text-primary mt-4 w-12 h-6 mr-2" />
            <Text className="w-max flex-1">
              {text()}
              <a className="text-primary underline" href="https://fresh.holiday/search">
                FRESH.holiday
              </a>
            </Text>
          </div>
        </div>
      </Container>
    </Box>
  );
};

export default BuyingAgentNextSteps;
