import { FC } from "react";
import { classNames } from "../../utils/string";

export type HorizontalDividerProps = {
  className?: string;
};

const HorizontalDivider: FC<HorizontalDividerProps> = ({ className }) => {
  return <hr className={classNames("h-[1px] w-full text-grey-regular bg-grey-regular my-4", className)} />;
};

export default HorizontalDivider;
